import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../Common/i18n";
import Accessories from "../../Canva/Accessories";
import Canva from "../../Canva/Canva";
import Konva from "../../Canva/Konva";
import Point from "../../Canva/Point";
import Polygon from "../../Canva/Polygon";
import SlideBar from "../../Common/SlideBar";
import store from "../../Common/Store";
import { heatAreaEvent } from "../global_helper";

import SinglePage from "../../Common/single-page-pdf";
import Tip from "./Tip";
import PanelWrapped from "./PanelWrapped";
import {
  onPressShift,
  onUnpressShift,
  addSpotReady,
  imageLoad,
  loadImgOnCanvas,
} from "./helper";

function FirstOverlay({ thermo, spot, setPDFurl, stateCanva }) {
  const { t } = useTranslation();
  return (
    <div
      className="modal_window"
      style={thermo || spot ? { display: "none" } : {}}
    >
      <div
        style={{
          position: "absolute",
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          margin: "auto",
          height: "200px",
        }}
      >
        {/* <h2
      style={{
        color: "#5F5F5F",
        fontSize: "24px",
        fontWeight: 400,
      }}
    >
      {t("Upload img")}
    </h2> */}
        <div style={{ marginBottom: "60px" }}>
          <label htmlFor="file_input" className="img-upload-btn">
            {t("Upload your room plan")}
          </label>
          <input
            type="file"
            id="file_input"
            name="file_input"
            accept=".jpg, .jpeg, .png, .svg, .pdf"
            style={{ display: "none", visibility: "hidden" }}
            onChange={(e) => {
              imageLoad(e, setPDFurl, stateCanva);
            }}
          />
        </div>
        <div>
          <label
            className="img-upload-btn"
            onClick={(e) => {
              document.querySelector(".modal_window").remove();
            }}
          >
            {t("Free-draw your room")}
          </label>
        </div>
      </div>
    </div>
  );
}

function ModeImg() {
  const { t } = useTranslation();
  const [stateCanva, setStateC] = useState(null);
  const stateAccess = useRef(new Accessories());
  const [stateArea, setStateArea] = useState(0);
  const [stateHeatArea, setStateHeatArea] = useState(0);
  const [stateStep, setStateStep] = useState(1);
  const [stateSpot, setStateSpot] = useState(1);
  const [statePoly, setStateP] = useState(null);
  const [stateSpotDraw, setStateSpotDraw] = useState(0);
  const [loading, setLoading] = useState(0);
  const [ajax_error, setError] = useState(0);
  const [PDFurl, setPDFurl] = useState("");
  const [heatAreaLoad, setHeatAreaLoad] = useState(0);

  const url_string = window.location.href;
  const url = new URL(url_string);
  const spot = url.searchParams.get("spot");
  const thermo = parseInt(url.searchParams.get("thermo"));

  useEffect(() => {
    document.removeEventListener("keydown", onPressShift);
    document.addEventListener("keydown", onPressShift);
    document.removeEventListener("keyup", onUnpressShift);
    document.addEventListener("keyup", onUnpressShift);
  }, []);

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem("lang"));

    if (localStorage.getItem("hm_labels") !== undefined) {
      if (localStorage.getItem("hm_labels") === "true") {
        stateAccess.current.labels = true;
      } else {
        stateAccess.current.labels = false;
      }
    } else {
      stateAccess.current.labels = false;
    }

    if (spot && stateSpot === 1) {
      if (parseInt(spot) === 1) {
        setStateStep(2);
      }
    }

    if (
      statePoly === null &&
      stateCanva !== null &&
      stateCanva.lastPolygon !== null
    ) {
      setStateP(stateCanva.lastPolygon);
    }

    if (stateCanva == null) {
      var canva = new Canva(window.innerWidth, window.innerHeight, Konva);
      canva.eraseCallback = () => {
        canva.deleteDraw(true);
        store.empty();
        setStateP(null);
      };
      canva.drawLine = true;

      if (store.polygon !== null) {
        canva.lastPolygon = store.polygon;

        canva.drawLine = false;
        var curr_pol = Canva.poly(
          canva.lastPolygon,
          Konva,
          Canva.polyDragFunc(canva, canva.lastPolygon, stateAccess.current),
          1,
          canva.lastPolygon.x,
          canva.lastPolygon.y
        );
        canva.init(
          [curr_pol],
          [
            stateAccess.current.addPoints(
              canva.lastPolygon,
              Canva,
              Accessories.doOnDrag(
                canva.lastPolygon,
                stateAccess.current,
                Polygon,
                canva,
                Point
              ),
              canva,
              curr_pol
            ),
            stateAccess.current.addAnglesButton(
              canva.lastPolygon,
              Canva,
              canva,
              curr_pol,
              Point
            ),
            Accessories.addSizeLabels(canva.lastPolygon, Canva, Konva),
          ],
          Canva,
          [Point, Polygon, Accessories, stateAccess.current]
        );

        if (store.spots.length > 0 && stateSpotDraw === 0) {
          store.spots = store.spots.filter((elm) => {
            let is = true;
            elm.points.forEach((itm) => {
              var intersects = canva.stage.getAllIntersections({
                x: itm.x + elm.x,
                y: itm.y + elm.y,
              });

              intersects = intersects.filter((item) => {
                return item.getName() === "poly";
              });

              if (intersects.length > 0) {
              } else {
                is = false;
              }
            });

            return is;
          });
        }

        if (store.spots.length > 0 && stateSpotDraw === 0) {
          if (canva.stage.find(".cold_spot").length > 0) {
            canva.stage.find(".cold_spot").forEach((itm) => {
              itm.destroy();
            });
          }
          if (canva.stage.find(".x_point").length > 0) {
            canva.stage.find(".x_point").forEach((itm) => {
              if (itm.attrs.parent_points() !== canva.lastPolygon.points) {
                itm.getAttr("parent_group").destroy();
              }
            });
          }

          store.spots.forEach((itm) => {
            addSpotReady(itm, canva, stateAccess.current);
          });
          setStateSpotDraw(1);
          heatAreaEvent(canva, setHeatAreaLoad, setStateHeatArea);
        }
      } else {
        canva.init(
          [],
          [],
          Canva,
          [Point, Polygon, Accessories, stateAccess.current],
          () => {
            if (canva.lastPolygon) {
              if (canva.lastPoint == null) {
                setStateArea(
                  Polygon.getAreaByShoelaceFormula(canva.lastPolygon.points)
                );

                if (store.polygon === null) {
                  store.polygon = canva.lastPolygon;
                }
                setHeatAreaLoad(1);
              }
            }
          }
        );
      }

      heatAreaEvent(canva, setHeatAreaLoad, setStateHeatArea);

      canva.stage.on("mouseup touchend", () => {
        if (canva.lastPolygon) {
          if (canva.lastPoint == null) {
            setStateArea(
              Polygon.getAreaByShoelaceFormula(canva.lastPolygon.points)
            );

            if (store.polygon === null) {
              store.polygon = canva.lastPolygon;
            }
          }
        }
        if (stateAccess.current.labels === true) {
          stateAccess.current.showLabels(canva);
        }
      });

      if (!stateAccess.current.labels) {
        stateAccess.current.hideLabels(canva);
      }

      stateAccess.current.hideAnglesButton(canva);

      setStateC(canva);
    } else {
    }

    if ((thermo && stateCanva) || stateStep !== 1) {
      stateCanva.stage.find(".poly").forEach((elm) => {
        elm.draggable(false);
      });
      stateCanva.stage.find(".x_point").forEach((elm) => {
        if (elm.getAttr("parent_shape") === stateCanva.stage.find(".poly")[0]) {
          elm.draggable(false);
        }
      });
    } else if (stateCanva) {
      stateCanva.stage.find(".poly").forEach((elm) => {
        elm.draggable(true);
      });
      stateCanva.stage.find(".x_point").forEach((elm) => {
        elm.draggable(true);
      });
    }

    if (thermo && curr_pol) {
      curr_pol.draggable(false);
      if (canva) {
        canva.stage.find(".x_point").forEach((elm) => {
          elm.draggable(false);
        });
        canva.stage.find(".cold_spot").forEach((elm) => {
          elm.draggable(false);
        });
      }
    }
  }, [
    stateCanva,
    stateArea,
    stateStep,
    stateSpot,
    statePoly,
    stateSpotDraw,
    i18n.language,
  ]);

  if (thermo !== 1) {
    store.thermo = [];
  }

  return (
    <div>
      <SinglePage
        src={PDFurl}
        callback={(dataURL) => {
          loadImgOnCanvas(dataURL, stateCanva);
        }}
      ></SinglePage>
      {false && (
        <FirstOverlay
          thermo={thermo}
          spot={spot}
          setPDFurl={setPDFurl}
          stateCanva={stateCanva}
        />
      )}
      <SlideBar
        canva={stateCanva}
        access={stateAccess.current}
        erase={(stateStep === 1 && !thermo) || stateStep === 2}
        eraseFunc={(e) => {
          setStateP(null);
        }}
        func={() => {
          if (statePoly) {
            setStateArea(Polygon.getAreaByShoelaceFormula(statePoly.points));
            setHeatAreaLoad(1);
          }
        }}
      />
      <div id="container"></div>
      {PanelWrapped(
        stateStep,
        stateCanva,
        statePoly,
        stateAccess.current,
        setStateHeatArea,
        setHeatAreaLoad
      )}
      {Tip(
        statePoly,
        stateStep,
        thermo,
        stateCanva,
        store,
        setStateHeatArea,
        setStateStep,
        setStateSpot,
        stateArea,
        stateHeatArea,
        loading,
        setLoading,
        setError,
        heatAreaLoad,
        setHeatAreaLoad
      )}
      <div
        style={
          loading === 0 && ajax_error === 0
            ? { display: "none" }
            : {
                width: "100vw",
                height: "100vh",
                backgroundColor: "#1c1c34",
                position: "fixed",
                inset: "0 0 0 0",
                zIndex: "3",
              }
        }
      >
        <div className="loadingImgWrap">
          <img
            src="./img/loading.svg"
            className="loadingImg"
            alt="loading..."
          ></img>
          <div>
            <span>{t("Processing")}</span>
          </div>
        </div>
      </div>
      <div
        style={
          ajax_error === 0
            ? { display: "none" }
            : {
                width: "100vw",
                height: "100vh",
                backgroundColor: "#1c1c34",
                position: "fixed",
                inset: "0 0 0 0",
                zIndex: "3",
              }
        }
      >
        <div className="loadingImgWrap">
          <div>
            <span>{t("Calc error")}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModeImg;
