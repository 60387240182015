import React from "react";
import { Link } from "react-router-dom";
import store from "../Common/Store";
import { useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";
import LangSelect from "../Common/LangSelect";

function Home() {
  const { t, i18n } = useTranslation();
  useEffect(() => {
    store.empty();
    i18n.changeLanguage(localStorage.getItem("lang"));
  }, [i18n.language]);
  return (
    <>
      <div
        style={{
          height: "100vh",
          width: "100vw",
          backgroundImage: "URL(img/start_img.png)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div
          style={{
            height: "100vh",
            width: "100vw",
            backgroundColor: "black",
            opacity: 0.6,
          }}
        ></div>
        <div
          style={{
            height: "50vh",
            width: "50vw",
            borderRadius: "20px",
            backgroundColor: "rgba(255, 255, 255, 0.2)",
            backdropFilter: "blur(10px)",
            border: "0.5px solid gray",
            boxShadow: "0 0 4px black",
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            top: 0,
            margin: "auto",
            minWidth: "360px",
            minHeight: "200px",
            maxWidth: "1000px",
            maxHeight: "500px",
          }}
        >
          <div
            style={{
              marginTop: "auto",
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
              top: 0,
              height: "80%",
            }}
          >
            <div>
              <img
                src="img/logo_main.png"
                style={{ width: "60%" }}
                alt="main logo"
              ></img>
            </div>

            <div>
              <Link to="/mode">
                <button className="start_btn">
                  <Trans i18nKey="Start"></Trans>
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <LangSelect func={i18n.changeLanguage} />
    </>
  );
}

export default Home;
