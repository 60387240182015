import Canva from "../Canva/Canva";
import Konva from "../Canva/Konva";
import Point from "../Canva/Point";
import Polygon from "../Canva/Polygon";
import getHeatAreaPadding_MonteCarlo from "../Canva/getHeatAreaPadding_MonteCarlo";
import store from "../Common/Store";

function heatAreaEvent(canva, setHeatAreaLoad, setStateHeatArea) {
  canva.stage.find(".x_point").forEach((elm) => {
    elm.on("dragmove", function () {
      setHeatAreaLoad(1);
      // getHeatAreaPadding_MonteCarlo(
      //   store,
      //   Polygon,
      //   Point,
      //   Konva,
      //   Canva,
      //   (val) => {
      //     setStateHeatArea(val);
      //     setHeatAreaLoad(0);
      //   }
      // );
    });
  });

  canva.stage.find(".cold_spot").forEach((elm) => {
    elm.on("dragmove", function () {
      setHeatAreaLoad(1);
      // getHeatAreaPadding_MonteCarlo(
      //   store,
      //   Polygon,
      //   Point,
      //   Konva,
      //   Canva,
      //   (val) => {
      //     setStateHeatArea(val);
      //     setHeatAreaLoad(0);
      //   }
      // );
    });
  });
}

export { heatAreaEvent };
