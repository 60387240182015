import React, { useState } from "react";
import { Document, Page } from "react-pdf";
import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `pdf.worker.min.js`;

export default function SinglePage(props) {


  function onDocumentLoadSuccess({ numPages }) {

    setTimeout(()=>{
    props.callback(document.querySelector("#pdf-image .react-pdf__Page__canvas").toDataURL());
    }, 1000);

  }


  return (
      <div id="pdf-image" style={{display: "none"}}>
      <Document
        file={props.src}
        renderMode="canvas"
      >
         <Page pageNumber={1} onLoadSuccess={onDocumentLoadSuccess}>

          </Page>
      </Document>
      </div>
  );
}
