import Accessories from "../../Canva/Accessories";
import Canva from "../../Canva/Canva";
import Konva from "../../Canva/Konva";
import Point from "../../Canva/Point";
import Polygon from "../../Canva/Polygon";

function onPressShift(e) {
  if (e.code === "ShiftLeft" || e.code === "ShiftRight") {
    Canva.shiftPressed = true;
  } else {
    Canva.shiftPressed = false;
  }
}

function onUnpressShift(e) {
  if (e.code === "ShiftLeft" || e.code === "ShiftRight") {
    Canva.shiftPressed = false;
  }
}

function getHeatArea(store, Polygon) {
  const room_area = store.polygon
    ? Polygon.getAreaByShoelaceFormula(store.polygon.points)
    : 0;
  var sum_spot_area = 0;
  store.spots.forEach((itm) => {
    const area = Polygon.getAreaByShoelaceFormula(itm.points);
    sum_spot_area += area;
  });

  return Number(parseFloat(room_area - sum_spot_area).toFixed(2));
}

const addThermoFunc = function (canva, polygon, id) {
  if (polygon) {
    canva.stage.find(".poly_outline").forEach((elm) => {
      if (elm.getAttr("parent_obj") === polygon) {
        elm.destroy();
      }
    });

    const poly = new canva.Konva.Line({
      points: [
        ...polygon.getPointsCoords(),
        polygon.getPointsCoords()[0],
        polygon.getPointsCoords()[1],
      ],
      stroke: "transparent",
      opacity: 0.5,
      strokeWidth: 18,
      name: "poly_outline",
      parent_obj: polygon,
      x: polygon.x,
      y: polygon.y,
    });

    canva.layer1.add(poly);

    const circle = new canva.Konva.Circle({
      x: 0,
      y: 0,
      radius: 10,
      fill: "#F15200",
      stroke: "white",
      strokeWidth: 1,
    });
    var groupOut = new canva.Konva.Group({
      x: polygon.points[0].x + polygon.x,
      y: polygon.points[0].y + polygon.y,
      draggable: true,
      name: "thermo",
      id: id,
      dragBoundFunc: function (pos) {
        var intersects = canva.stage.getAllIntersections({
          x: pos.x,
          y: pos.y,
        });
        intersects = intersects.filter((item) => {
          return item.getName() === "poly_outline" || item.getName() === "poly";
        });
        if (intersects.length > 1) {
          return pos;
        }
        var intersects1 = canva.stage.getAllIntersections({
          x: this.x(),
          y: pos.y,
        });
        intersects1 = intersects1.filter((item) => {
          return item.getName() === "poly_outline" || item.getName() === "poly";
        });
        if (intersects1.length > 1) {
          return { x: this.x(), y: pos.y };
        }

        var intersects2 = canva.stage.getAllIntersections({
          x: pos.x,
          y: this.y(),
        });
        intersects2 = intersects2.filter((item) => {
          return item.getName() === "poly_outline" || item.getName() === "poly";
        });
        if (intersects2.length > 1) {
          return { x: pos.x, y: this.y() };
        }

        return { x: this.x(), y: this.y() };
      },
    });
    var textOut = new canva.Konva.Text({
      text: "T",
      x: 0,
      y: 0,
      fontSize: 12,
      fontStyle: "bold",
      fill: "black",
      fontFamily: "Calibri",
    });
    textOut.x(textOut.x() - textOut.width() / 2);
    textOut.y(textOut.y() - textOut.height() / 2);
    groupOut.add(circle);
    groupOut.add(textOut);

    canva.layer2.add(groupOut);
  }
  return groupOut;
};

function addSpotReady(polygon, canva, accessories) {
  const pol1 = Canva.spot(
    polygon,
    Konva,
    Canva.spotDragFunc(canva, polygon, accessories),
    1,
    polygon.x,
    polygon.y,
    canva,
    accessories
  );

  polygon.bindPoints();
  polygon.canva_obj = pol1;

  Canva.drawAll([pol1], canva.layer1);
  Canva.drawAll(
    [
      accessories.addPoints(
        polygon,
        Canva,
        Accessories.doSpotOnDrag(polygon, accessories, Polygon, canva, Point),
        canva,
        pol1
      ),
    ],
    canva.layer2
  );
  return polygon;
}

function addSpot(type, w = 100, h = 100, x = 0, y = 0, canva, accessories) {
  var init_point_x = 0;
  var init_point_y = 0;

  var point7 = new Point(init_point_x, init_point_y);
  var point8 = new Point(init_point_x, init_point_y + Canva.scale * (h / 100));
  var point9 = new Point(
    init_point_x + Canva.scale * (w / 100),
    init_point_y + Canva.scale * (h / 100)
  );
  var point10 = new Point(init_point_x + Canva.scale * (w / 100), init_point_y);
  var polygon = new Polygon([point7, point8, point9, point10]);
  if (type === 2) {
    polygon = new Polygon([point7, point8, point9]);
  }
  polygon.type = type;
  polygon.width = w;
  polygon.height = h;

  const pol1 = Canva.spot(
    polygon,
    Konva,
    Canva.spotDragFunc(canva, polygon, accessories),
    1,
    x,
    y,
    canva,
    accessories
  );
  polygon.canva_obj = pol1;
  polygon.bindPoints();

  Canva.drawAll([pol1], canva.layer1);
  Canva.drawAll(
    [
      accessories.addPoints(
        polygon,
        Canva,
        Accessories.doSpotOnDrag(polygon, accessories, Polygon, canva, Point),
        canva,
        pol1
      ),
    ],
    canva.layer2
  );
  return polygon;
}

function imageLoad(e, setPDFurl, stateCanva) {
  const URL = window.webkitURL || window.URL;
  const url = URL.createObjectURL(e.target.files[0]);

  if (e.target.files[0].type === "application/pdf") {
    setPDFurl(url);
  } else {
    loadImgOnCanvas(url, stateCanva);
  }
}

function loadImgOnCanvas(url, stateCanva) {
  const img = new Image();
  img.src = url;
  img.onload = function () {
    var img_width = img.width;
    var img_height = img.height;

    var max = 200;
    var ratio = img_width > img_height ? img_width / max : img_height / max;

    var theImg = new Konva.Image({
      image: img,
      x: window.innerWidth / 2 - img_width / ratio / 2,
      y: window.innerHeight / 2 - img_height / ratio / 2,
      width: img_width / ratio,
      height: img_height / ratio,
      draggable: true,
      rotation: 0,
      name: "subimg",
    });

    stateCanva.layer1.add(theImg);
    stateCanva.layer1.draw();

    var transformer = new Konva.Transformer({
      padding: 14,
      borderStroke: "orange",
      anchorFill: "orange",
      anchorStroke: "darkorange",
      anchorCornerRadius: 10,
      name: "subImgWrap",
    });
    stateCanva.layer1.add(transformer);
    transformer.nodes([theImg]);
    transformer.zIndex(0);
    theImg.zIndex(0);
    stateCanva.layer1.batchDraw();
    document.querySelector(".modal_window").remove();
  };
}

export {
  onPressShift,
  onUnpressShift,
  getHeatArea,
  addThermoFunc,
  addSpotReady,
  addSpot,
  imageLoad,
  loadImgOnCanvas,
};
