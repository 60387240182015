class Accessories {
  constructor() {
    this.currentHandle = null;
    this.currentHandleId = null;
    this.angles = true;
    this.labels = true;
    this.justify = false;
  }

  static doOnDrag(shape, accessories, Polygon, canva, Point) {
    return function (elm, uid, helper) {
      return function (pos, e) {
        accessories.currentHandle = elm;
        accessories.currentHandleId = uid;
        if (
          e.clientX + 20 > canva.width ||
          e.clientX - 20 < 0 ||
          e.clientY + 20 > canva.height ||
          e.clientY - 20 < 0
        ) {
          return { x: elm.x + shape.x, y: elm.y + shape.y };
        }

        const buff_x = elm.x;
        const buff_y = elm.y;
        elm.x = pos.x - shape.x;
        elm.y = pos.y - shape.y;
        elm.recalculateBinX();
        elm.recalculateBinY();

        if (
          shape.hasCoincident(helper.coincident).length > 0 ||
          shape.hasIntersection(helper.intersects).length > 0 ||
          helper
            .getSidesLength([...shape.points, shape.points[0]])
            .filter((item) => {
              return item < 20;
            }).length > 0
        ) {
          elm.x = buff_x;
          elm.y = buff_y;
          elm.recalculateBinX();
          elm.recalculateBinY();
          return { x: elm.x + shape.x, y: elm.y + shape.y };
        }

        const parent_obj_id = canva.stage
          .find("#" + accessories.currentHandleId)[0]
          .getAttr("parent_id");

        const parent_shape = canva.stage.find(
          "#" + accessories.currentHandleId
        )[0].attrs.parent_shape;

        canva.stage
          .find("#" + parent_obj_id)[0]
          .points(shape.getPointsCoords());

        canva.stage.find(".angl_point").forEach((itm) => {
          if (itm.getAttr("parent_points")() === shape.points)
            itm.getAttr("parent_group").destroy();
        });

        canva.stage.find(".del_angl_point").forEach((itm) => {
          if (itm.getAttr("parent_points")() === shape.points) {
            itm.getAttr("parent_group").destroy();
          }
        });

        helper.polyDragFunc(
          canva,
          shape,
          accessories,
          false
        )({ x: shape.x, y: shape.y }, e);

        helper.drawAll(
          [
            accessories.addAnglesButton(
              shape,
              helper,
              canva,
              parent_shape,
              Point,
              Polygon
            ),
          ],
          canva.layer2
        );
        if (!accessories.angles) {
          canva.stage.find(".angl_point").forEach((itm) => {
            if (itm.getAttr("parent_points")() === shape.points) itm.hide();
          });

          canva.stage.find(".del_angl_point").forEach((itm) => {
            if (itm.getAttr("parent_points")() === shape.points) itm.hide();
          });
        }

        return pos;
      };
    };
  }

  static doSpotOnDrag(shape, accessories, Polygon, canva, Point) {
    const Canva = canva.constructor;
    return function (elm, uid, helper) {
      return function (pos, e) {
        accessories.currentHandle = elm;
        accessories.currentHandleId = uid;
        if (
          e.clientX + 20 > canva.width ||
          e.clientX - 20 < 0 ||
          e.clientY + 20 > canva.height ||
          e.clientY - 20 < 0
        ) {
          return { x: elm.x + shape.x, y: elm.y + shape.y };
        }

        const buff_x = elm.x;
        const buff_y = elm.y;
        elm.x = pos.x - shape.x;
        elm.y = pos.y - shape.y;
        elm.recalculateBinX();
        elm.recalculateBinY();

        if (
          shape.hasCoincident(helper.coincident).length > 0 ||
          shape.hasIntersection(helper.intersects).length > 0 ||
          helper
            .getSidesLength([...shape.points, shape.points[0]])
            .filter((item) => {
              return item < 20;
            }).length > 0
        ) {
          elm.x = buff_x;
          elm.y = buff_y;
          elm.recalculateBinX();
          elm.recalculateBinY();
          return { x: elm.x + shape.x, y: elm.y + shape.y };
        }

        const parent_obj_id = canva.stage
          .find("#" + accessories.currentHandleId)[0]
          .getAttr("parent_id");

        const parent_shape = canva.stage.find(
          "#" + accessories.currentHandleId
        )[0].attrs.parent_shape;

        canva.stage
          .find("#" + parent_obj_id)[0]
          .points(shape.getPointsCoords());

        helper.spotDragFunc(
          canva,
          shape,
          accessories,
          false
        )({ x: shape.x, y: shape.y }, e, parent_shape);

        helper.drawAll(
          [Accessories.addSizeSpotLabels(shape, Canva, canva.Konva)],
          canva.layer2
        );

        if (canva.debounceSpotLabelsClean) {
          clearTimeout(canva.debounceSpotLabelsClean);
        }

        canva.debounceSpotLabelsClean = setTimeout(() => {
          canva.stage.find(".length_label_spot").forEach((elm) => {
            if (elm.getAttr("parent_obj") === shape) {
              elm.hide();
            }
          });
        }, 1000);

        return pos;
      };
    };
  }

  addPoints(poly, helper, doOnDragFunc, canva, shape) {
    const group = new canva.Konva.Group({
      x: 0,
      y: 0,
      rotation: 0,
    });

    const callback = function (elm) {
      const uid = helper.getUID("x_point_", elm.x, elm.y);

      const config = {
        radius: 5,
        fill: "orange",
        stroke: "darkorange",
        strokeWidth: 1,
        x: elm.x + poly.x,
        y: elm.y + poly.y,
        draggable: true,
        name: "x_point",
        id: uid,
        parent_id: shape.getId(),
        dragBoundFunc: doOnDragFunc(elm, uid, helper),
        point_obj: elm,
        parent_points: () => {
          return poly.points;
        },
        parent_group: group,
        parent_shape: shape,
      };

      group.add(new canva.Konva.Circle(config));
    };
    poly.points.forEach(callback, this);
    shape.setAttr("labels", group);
    return group;
  }

  addAnglesButton(
    poly,
    helper,
    canva,
    shape,
    Point,
    Polygon,
    accessories = this
  ) {
    const arr = poly.points;
    const group = new canva.Konva.Group({
      x: 0,
      y: 0,
      rotation: 0,
    });
    const callback = function (elm) {
      if (elm.cold !== true) {
        const uid = helper.getUID("angl_point_", elm.x, elm.y);

        var config = {
          radius: 6,
          fill: "white",
          stroke: "black",
          strokeWidth: 2,
          x: elm.x + poly.x,
          y: elm.y + poly.y,
          name: "angl_point",
          id: uid,
          parent_id: shape.getId(),
          point_obj: elm,
          parent_points: () => {
            return poly.points;
          },
          parent_group: group,
        };

        const circle = new canva.Konva.Circle(config);

        circle.on("click tap", function () {
          var index = arr.indexOf(this.getAttr("point_obj"));
          var neighbor = poly.neighborPoints(this.getAttr("point_obj"));
          var centers = helper.getSidesCenters(neighbor);

          var p2 = new Point(centers[1].x, centers[1].y);
          p2.cold = true;

          var p1 = new Point(centers[0].x, centers[0].y);
          p1.cold = true;
          p1.line = [p1, p2];

          arr.splice(index, 1, p2);
          arr.splice(index, 0, p1);
          shape.points(poly.getPointsCoords());

          canva.stage.find(".x_point").forEach((itm) => {
            if (itm.getAttr("parent_points")() === arr)
              itm.getAttr("parent_group").destroy();
          });

          canva.stage.find(".angl_point").forEach((itm) => {
            if (itm.getAttr("parent_points")() === arr)
              itm.getAttr("parent_group").destroy();
          });

          helper.drawAll(
            [
              accessories.addPoints(
                poly,
                helper,
                Accessories.doOnDrag(poly, accessories, Polygon, canva, Point),
                canva,
                shape
              ),
              accessories.addAnglesButton(
                poly,
                helper,
                canva,
                shape,
                Point,
                Polygon,
                accessories
              ),
            ],
            canva.layer2
          );

          if (accessories.autoBind === true) {
            poly.bindPoints();
          }

          canva.stage.find(".length_label").forEach((elm) => {
            if (elm.getAttr("parent_obj") === poly) {
              elm.destroy();
            }
          });

          helper.drawAll(
            [Accessories.addSizeLabels(poly, helper, canva.Konva)],
            canva.layer2
          );
          if (accessories.labels) {
            accessories.showLabels(canva);
          } else {
            accessories.hideLabels(canva);
          }
        });

        group.add(circle);
      }
    };

    arr.forEach(callback, this);

    const callback2 = function (elm) {
      const uid = helper.getUID("del_angl_point_", elm.x, elm.y);

      const point_obj = elm;
      const center = helper.getSidesCenters([
        { x: point_obj.line[0].x, y: point_obj.line[0].y },
        { x: point_obj.line[1].x, y: point_obj.line[1].y },
      ]);

      const config = {
        radius: 6,
        fill: "red",
        stroke: "darkred",
        strokeWidth: 2,
        x: center[0].x + poly.x,
        y: center[0].y + poly.y,
        name: "del_angl_point",
        id: uid,
        parent_id: shape.getId(),
        point_obj: elm,
        parent_points: () => {
          return poly.points;
        },
        parent_group: group,
      };

      const circle2 = new canva.Konva.Circle(config);

      circle2.on("click tap", function () {
        canva.stage.find(".x_point").forEach((itm) => {
          if (itm.getAttr("parent_points")() === poly.points)
            itm.getAttr("parent_group").destroy();
        });

        canva.stage.find(".angl_point").forEach((itm) => {
          if (itm.getAttr("parent_points")() === poly.points)
            itm.getAttr("parent_group").destroy();
        });

        canva.stage.find(".del_angl_point").forEach((itm) => {
          if (itm.getAttr("parent_points")() === poly.points)
            itm.getAttr("parent_group").destroy();
        });

        canva.stage.find(".length_label").forEach((itm) => {
          if (itm.getAttr("parent_obj") === poly) itm.destroy();
        });

        poly.points.forEach((item, i) => {
          if (this.getAttr("point_obj").line[0] === item) {
            const neighbors1 = poly.neighborPoints(
              this.getAttr("point_obj").line[0]
            );
            const neighbors2 = poly.neighborPoints(
              this.getAttr("point_obj").line[1]
            );
            const neighbors1_main = neighbors1[0];
            const neighbors2_main = neighbors2[2];

            if (
              !helper.inLine(
                neighbors1_main.x,
                neighbors1_main.y,
                this.getAttr("point_obj").line[0].x,
                this.getAttr("point_obj").line[1].y,
                this.getAttr("point_obj").line[0].x,
                this.getAttr("point_obj").line[1].y,
                neighbors2_main.x,
                neighbors2_main.y
              )
            ) {
              const a = helper.getSidesLength([
                { x: neighbors1_main.x, y: neighbors1_main.y },
                {
                  x: this.getAttr("point_obj").line[0].x,
                  y: this.getAttr("point_obj").line[1].y,
                },
              ])[0];

              const b = helper.getSidesLength([
                {
                  x: this.getAttr("point_obj").line[0].x,
                  y: this.getAttr("point_obj").line[1].y,
                },
                { x: neighbors2_main.x, y: neighbors2_main.y },
              ])[0];

              const c = helper.getSidesLength([
                { x: neighbors1_main.x, y: neighbors1_main.y },
                { x: neighbors2_main.x, y: neighbors2_main.y },
              ])[0];

              if (
                Math.abs(a ** 2 + b ** 2 - c ** 2).toString().length <=
                (c ** 2).toString().length - 2
              ) {
                poly.points[i] = new Point(
                  this.getAttr("point_obj").line[0].x,
                  this.getAttr("point_obj").line[1].y
                );
              } else {
                poly.points[i] = new Point(
                  this.getAttr("point_obj").line[1].x,
                  this.getAttr("point_obj").line[0].y
                );
              }
            } else {
              poly.points[i] = new Point(
                this.getAttr("point_obj").line[1].x,
                this.getAttr("point_obj").line[0].y
              );
            }
          }
        }, this);

        const new_arr = poly.points.filter((item) => {
          return this.getAttr("point_obj").line[1] !== item;
        });

        poly.points = new_arr;

        helper.drawAll(
          [
            accessories.addPoints(
              poly,
              helper,
              Accessories.doOnDrag(poly, accessories, Polygon, canva, Point),
              canva,
              shape
            ),
            accessories.addAnglesButton(
              poly,
              helper,
              canva,
              shape,
              Point,
              Polygon
            ),
          ],
          canva.layer2
        );

        helper.drawAll(
          [Accessories.addSizeLabels(poly, helper, canva.Konva)],
          canva.layer2
        );
        if (accessories.labels) {
          accessories.showLabels(canva);
        } else {
          accessories.hideLabels(canva);
        }

        shape.points(poly.getPointsCoords());
        if (accessories.autoBind === true) {
          poly.bindPoints();
        }
      });

      group.add(circle2);
    };

    const lines = arr.filter((item) => {
      return item.line !== undefined;
    });

    lines.forEach(callback2, this);

    return group;
  }

  static arraysMatch(arr1, arr2) {
    if (arr1.length !== arr2.length) return false;

    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) return false;
    }

    return true;
  }

  static addSizeSpotLabels(poly, Canva, Konva) {
    const centers = Canva.getSidesCenters([...poly.points, poly.points[0]]);

    const group = new Konva.Group({
      x: 0,
      y: 0,
      rotation: 0,
      name: "length_label_spot",
      parent_obj: poly,
    });

    centers.forEach((elm) => {
      const text = new Konva.Text({
        text: `${(elm.length / Canva.scale).toFixed(2)} m`,
        fontSize: 12,
        lineHeight: 1.2,
        padding: 4,
        fill: "white",
        name: "l_text",
      });

      const tw = text.width();
      const th = text.height();

      const pos_obj = {
        x: poly.x + elm.x,
        y: poly.y + elm.y,
        child_text: text,
        name: "l_labels",
        parent_obj: poly,
        center_line: elm,
      };

      const label = new Konva.Label(pos_obj);
      label.offsetX(tw / 2);
      label.offsetY(th / 2);
      label.add(
        new Konva.Tag({
          fill: "#2a354c",
          stroke: "#868686",
          strokeWidth: 1,
          cornerRadius: 5,
        })
      );

      label.add(text);

      group.add(label);
    });

    return group;
  }

  static addSizeLabels(poly, Canva, Konva) {
    const centers = Canva.getSidesCenters([...poly.points, poly.points[0]]);

    const group = new Konva.Group({
      x: 0,
      y: 0,
      rotation: 0,
      name: "length_label",
      parent_obj: poly,
    });

    centers.forEach((elm) => {
      const text = new Konva.Text({
        text: `${(elm.length / Canva.scale).toFixed(2)} m`,
        fontSize: 12,
        lineHeight: 1.2,
        padding: 4,
        fill: "white",
        name: "l_text",
      });

      const tw = text.width();
      const th = text.height();

      const pos_obj = {
        x: poly.x + elm.x,
        y: poly.y + elm.y,
        child_text: text,
        name: "l_labels",
        parent_obj: poly,
        center_line: elm,
      };

      const label = new Konva.Label(pos_obj);
      label.offsetX(tw / 2);
      label.offsetY(th / 2);
      label.add(
        new Konva.Tag({
          fill: "#2a354c",
          stroke: "#868686",
          strokeWidth: 1,
          cornerRadius: 5,
        })
      );

      label.add(text);

      group.add(label);
    });

    return group;
  }

  static justLabels(poly, canva, accessories) {
    accessories.showLabels(canva);
    const labels = canva.stage.find(".l_labels");
    const padding = 10;

    const curr_lbls = labels.filter((item) => {
      return item.getAttr("parent_obj") === poly;
    });

    curr_lbls.forEach((elm) => {
      const text = elm.attrs.child_text;

      const tw = text.width();
      const th = text.height();

      const center = { ...elm.getAttr("center_line") };
      center.x += poly.x;
      center.y += poly.y;

      let res_pos = {
        x: center.x,
        y: center.y,
      };

      const pos_obj1 = {
        x: center.x,
        y: center.y,
      };

      const pos_obj2 = {
        x: center.x - (tw / 2 + padding),
        y: center.y,
      };

      const pos_obj3 = {
        x: center.x,
        y: center.y - (th / 2 + padding),
      };

      const pos_obj4 = {
        x: center.x + (tw / 2 + padding),
        y: center.y,
      };

      const pos_obj5 = {
        x: center.x,
        y: center.y + (th / 2 + padding),
      };

      const pos_arr = [pos_obj1, pos_obj2, pos_obj3, pos_obj4, pos_obj5];

      pos_arr.forEach((itm) => {
        if (canva.stage.getIntersection(itm) == null) {
          res_pos = itm;
        } else {
          if (
            canva.stage.getIntersection(itm).getName() === "l_text" ||
            canva.stage.getIntersection(itm).getName() === "grid_line"
          ) {
            res_pos = itm;
          }
        }
      });

      elm.x(res_pos.x);
      elm.y(res_pos.y);
    });
  }

  hideAnglesButton(canva) {
    this.angles = false;
    canva.stage.find(".angl_point").forEach((itm) => {
      itm.hide();
    });
    canva.stage.find(".del_angl_point").forEach((itm) => {
      itm.hide();
    });
  }

  showAnglesButton(canva) {
    this.angles = true;
    canva.stage.find(".angl_point").forEach((itm) => {
      itm.show();
    });
    canva.stage.find(".del_angl_point").forEach((itm) => {
      itm.show();
    });
  }

  hideLabels(canva) {
    canva.stage.find(".length_label").forEach((itm) => {
      itm.hide();
    });
  }

  showLabels(canva) {
    canva.stage.find(".length_label").forEach((itm) => {
      itm.show();
    });
  }
}

export default Accessories;
