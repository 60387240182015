function debounce(func, delay) {
  let timerId;
  return function () {
    clearTimeout(timerId);
    timerId = setTimeout(() => func.apply(this, arguments), delay);
  };
}

const getHeatAreaPadding_MonteCarlo = debounce(
  (store, Polygon, Point, Konva, Canva, setStateHeatArea) => {
    return getHeatAreaPadding_MonteCarlo_main(
      store,
      Polygon,
      Point,
      Konva,
      Canva,
      setStateHeatArea
    );
  },
  1200
);

function getHeatAreaPadding_MonteCarlo_main(
  store,
  Polygon,
  Point,
  Konva,
  Canva,
  setStateHeatArea
) {
  const main_poly = new Konva.Line({
    points: store.polygon.getPointsCoords(),
    fill: "Purple",
    stroke: "black",
    strokeWidth: 2,
    closed: true,
    x: store.polygon.x,
    y: store.polygon.y,
  });

  const ClientRect = main_poly.getClientRect();

  const point_1 = new Point(ClientRect.x, ClientRect.y);
  const point_2 = new Point(ClientRect.x + ClientRect.width, ClientRect.y);
  const point_3 = new Point(
    ClientRect.x + ClientRect.width,
    ClientRect.y + ClientRect.height
  );
  const point_4 = new Point(ClientRect.x, ClientRect.y + ClientRect.height);
  const point_5 = new Point(ClientRect.x, ClientRect.y);

  const rect_poly = new Polygon([point_1, point_2, point_3, point_4, point_5]);

  let full_amount = 0;

  let padding = Canva.scale * 0.02;

  const points = [];

  for (let j = 0; j < ClientRect.height; j += 2) {
    for (let i = 0; i < ClientRect.width; i += 2) {
      full_amount++;
      if (
        store.polygon.is_inside([ClientRect.x + i, ClientRect.y + j]) ===
          true &&
        store.polygon.is_inside([
          ClientRect.x + padding + i,
          ClientRect.y + j,
        ]) === true &&
        store.polygon.is_inside([
          ClientRect.x - padding + i,
          ClientRect.y + j,
        ]) === true &&
        store.polygon.is_inside([
          ClientRect.x + i,
          ClientRect.y + padding + j,
        ]) === true &&
        store.polygon.is_inside([
          ClientRect.x + i,
          ClientRect.y - padding + j,
        ]) === true &&
        store.polygon.is_inside([
          ClientRect.x + padding + i,
          ClientRect.y + padding + j,
        ]) === true &&
        store.polygon.is_inside([
          ClientRect.x - padding + i,
          ClientRect.y - padding + j,
        ]) === true &&
        store.polygon.is_inside([
          ClientRect.x - padding + i,
          ClientRect.y + padding + j,
        ]) === true &&
        store.polygon.is_inside([
          ClientRect.x + padding + i,
          ClientRect.y - padding + j,
        ]) === true
      ) {
        let point = new Konva.Circle({
          x: ClientRect.x + i,
          y: ClientRect.y + j,
          radius: 2,
          fill: "darkred",
          name: "mc-point",
        });
        points.push(point);
      }
    }
  }

  const filtered_points = points.filter((pt) => {
    let is = true;

    store.spots.forEach((pol) => {
      if (
        pol.is_inside([pt.x(), pt.y()]) === true ||
        pol.is_inside([pt.x() + padding, pt.y()]) === true ||
        pol.is_inside([pt.x() - padding, pt.y()]) === true ||
        pol.is_inside([pt.x(), pt.y() + padding]) === true ||
        pol.is_inside([pt.x(), pt.y() - padding]) === true ||
        pol.is_inside([pt.x() + padding, pt.y() + padding]) === true ||
        pol.is_inside([pt.x() - padding, pt.y() - padding]) === true ||
        pol.is_inside([pt.x() - padding, pt.y() + padding]) === true ||
        pol.is_inside([pt.x() + padding, pt.y() - padding]) === true
      ) {
        is = false;
      } else {
      }
    });

    return is;
  });

  const area = (
    Polygon.getAreaByShoelaceFormula(rect_poly.points) *
    (filtered_points.length / full_amount)
  ).toFixed(2);

  if (setStateHeatArea) {
    setStateHeatArea(area);
  }

  return area;
}
export { getHeatAreaPadding_MonteCarlo_main, getHeatAreaPadding_MonteCarlo };
export default getHeatAreaPadding_MonteCarlo;
