import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ReactSVG } from "react-svg";
import del from "../images/del.svg";
import refresh from "../images/refresh.svg";

function TipBox(props) {
  const { t, i18n } = useTranslation();
  const openH = -300;
  const closeH = -120;
  const [state, setState] = useState(
    localStorage.getItem("wasClosed") ? closeH : openH
  );
  const [calc_type, setCalcType] = useState(
    localStorage.getItem("calc_type") === "true" ? true : false
  );
  let rot = "rotate(0deg)";
  let top = 0;

  if (state === openH) {
    rot = "rotate(180deg)";
    top = "-2px";
  }

  const nextBtn = () => {
    if (props.funcNext) {
      return (
        <>
          {props.slider && (
            <div style={{ marginTop: "-6px" }}>
              <div style={{ color: "white" }}>{t("Calculation type")}</div>
              <div className="slider_wrap">
                <span
                  className="slider_text"
                  style={{
                    fontSize: "14px",
                    marginLeft: "12px",
                    marginTop: "6px",
                  }}
                >
                  {1}
                </span>
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={
                      localStorage.getItem("calc_type") === "true"
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      setCalcType(e.target.checked);
                      localStorage.setItem("calc_type", e.target.checked);
                    }}
                  />
                  <span className="slider_check round"></span>
                </label>
                <span
                  className="slider_text"
                  style={{
                    fontSize: "14px",
                    marginLeft: "2px",
                    marginRight: "6px",
                    marginTop: "6px",
                  }}
                >
                  {2}
                </span>
              </div>
            </div>
          )}
          <button
            onClick={props.funcNext}
            className="next_btn"
            style={{
              display: "flex",
              justifyContent: "space-around",
              margin: "10px",
            }}
          >
            <span style={{ marginTop: "10px", marginLeft: "20px" }}>
              {t("Next step")}
            </span>
            <span style={{ fontSize: "28px" }}>›</span>
          </button>
        </>
      );
    } else {
      if (props.next) {
        return (
          <>
            <Link to={`${props.next}`}>
              <button
                className="next_btn"
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  margin: "10px",
                }}
              >
                <span style={{ marginTop: "10px", marginLeft: "20px" }}>
                  {t("Next step")}
                </span>
                <span style={{ fontSize: "28px" }}>›</span>
              </button>
            </Link>
          </>
        );
      } else {
        return "";
      }
    }
  };

  const prevBtn = () => {
    if (props.funcPrev) {
      return (
        <button
          onClick={props.funcPrev}
          className="prev_btn"
          style={{
            display: "flex",
            justifyContent: "space-around",
            margin: "10px",
          }}
        >
          <img src="img/right-arrow.png" height="40px" alt="arrow" />
        </button>
      );
    } else {
      if (props.prev) {
        return (
          <Link to={`${props.prev}`}>
            <button
              className="prev_btn"
              style={{
                display: "flex",
                justifyContent: "space-around",
                margin: "10px",
              }}
            >
              <img src="img/right-arrow.png" height="40px" alt="arrow" />
            </button>
          </Link>
        );
      } else {
        return "";
      }
    }
  };

  return (
    <>
      {props.area ? (
        <div className="areaLabel">
          <span
            style={{
              display: "inline-block",
              width: "150px",
              marginRight: "10px",
            }}
          >
            <span>
              {props.area} m<sup>2</sup>
            </span>
            <span className="ht_label1" style={{ display: "block" }}>
              {t("Area")}
            </span>
          </span>

          {false && props.heat_area !== undefined && (
            <span>
              <span
                className="ht_label1"
                style={{ display: "inline-block", width: "150px" }}
              >
                <span className="ht_label2">
                  {props.heatAreaLoad === 0 ? props.heat_area : "..."} m
                  <sup>2</sup>
                </span>{" "}
                {props.heatAreaLoad === 1 && (
                  <button
                    className="refreshBtn"
                    onClick={() => {
                      props.refreshArea();
                    }}
                  >
                    <ReactSVG
                      style={{
                        width: "32px",
                        height: "26px",
                      }}
                      src={`${refresh}`}
                    />
                  </button>
                )}
                <span className="ht_label1" style={{ display: "block" }}>
                  {t("Heated area")}
                </span>
              </span>
            </span>
          )}
        </div>
      ) : (
        ""
      )}
      <div
        style={{
          width: "70vw",
          minWidth: "320px",
          maxWidth: "1080px",
          height: 0,
          position: "fixed",
          zIndex: 50,
          bottom: 0,
          left: "140px",
          right: 0,
          top: "100%",
          margin: "auto",
          marginTop: state + "px",
          transition: "margin-top 2s",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent:
              props.prev || props.funcPrev ? "space-between" : "right",
            height: "0",
          }}
        >
          {prevBtn()}

          <div
            className="dragDelBlock"
            style={props.spotDel ? { height: "60px" } : { display: "none" }}
            onMouseEnter={(e) => {
              props.spotDel(e.pageX, e.pageY);
            }}
          >
            <ReactSVG src={`${del}`} />
            <span>{t("Drag here")}</span>
          </div>

          {nextBtn()}
        </div>

        <div
          style={{
            marginTop: "80px",
            backgroundColor: "#2d354c",
            color: "white",
            border: "1px solid orange",
            borderRadius: "20px",
            height: "400px",
            padding: "10px",
          }}
        >
          <div
            style={{
              cursor: "pointer",
              position: "absolute",
              bottom: 0,
              top: "65px",
              left: "25px",
              right: 0,
              fontSize: "12px",
              width: "45px",
              height: "14px",
              backgroundColor: "#2d354c",
              color: "orange",
              border: "1px solid orange",
              borderRadius: "15px 15px 0 0",
            }}
            onClick={() => {
              if (state === closeH) {
                setState(openH);
              } else {
                setState(closeH);
                localStorage.setItem("wasClosed", true);
              }
            }}
          >
            <span
              style={{
                display: "inline-block",
                position: "relative",
                transform: rot,
                top: top,
              }}
            >
              ^
            </span>
          </div>
          <div style={{ textAlign: "left" }}>
            <span>
              <img alt="img" src="img/info.svg"></img>
            </span>
            <span style={{ marginLeft: "6px", verticalAlign: "super" }}>
              {t("Step")} {props.tip} {t("from")} 6
            </span>
            <p>{t("tip_" + props.tip)}</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default TipBox;
