import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from "../lang/en/translation.json";
import translationUA from "../lang/ua/translation.json";
import translationDE from "../lang/de/translation.json";
import translationPL from "../lang/pl/translation.json";
import translationRU from "../lang/ru/translation.json";
import translationES from "../lang/es/translation.json";
import translationIT from "../lang/it/translation.json";

// the translations
const resources = {
  en: {
    translation: translationEN,
  },
  ua: {
    translation: translationUA,
  },
  de: {
    translation: translationDE,
  },
  pl: {
    translation: translationPL,
  },
  ru: {
    translation: translationRU,
  },
  it: {
    translation: translationIT,
  },
  es: {
    translation: translationES,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en",
    fallbackLng: "en", // use en if detected lng is not available
    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
