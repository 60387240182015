import TipBox from "../../Common/TipBox";
import Panel from "../../Common/Panel";
import store from "../../Common/Store";
import RadionBtnBlock from "./RadionBtnBlock";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

function FloorType(props) {
  const [state, setState] = useState(6);
  const [floorType, setFloorType] = useState(1);
  const [page, setPageState] = useState("/");
  const { t, i18n } = useTranslation();

  useEffect(() => {
    store.thermo = [];
    store.floor1 = state;
    store.floorType = floorType;
    const url_string = window.location.href;
    const url = new URL(url_string);
    const c = url.searchParams.get("fig_type");
    const img = url.searchParams.get("img");
    if (c) {
      setPageState("/standartmode?fig_type=" + parseInt(c));
    } else {
      if (img) {
        setPageState("/imgmode?");
      }
    }

    i18n.changeLanguage(localStorage.getItem("lang"));
  }, [i18n.language, state, floorType]);

  return (
    <>
      <div>
        <h1
          style={{
            color: "#5F5F5F",
            fontSize: "24px",
            fontWeight: 400,
            textAlign: "center",
            top: "-700px",
            bottom: 0,
            left: "160px",
            right: 0,
            position: "absolute",
            margin: "auto",
            height: "40px",
          }}
        >
          {t("Choose Floor")}
        </h1>
        <div
          style={{
            height: "500px",
            position: "absolute",
            zIndex: 10,
            bottom: 0,
            left: "160px",
            right: 0,
            top: "-175px",
            margin: "auto",
          }}
        >
          <div>
            <RadionBtnBlock
              style={{ width: "840px" }}
              header={
                <>
                  <span>{t("Tile floor")}</span> <span>{t("Stone floor")}</span>
                </>
              }
            >
              <div
                style={{
                  width: "220px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ position: "relative", width: "100px" }}>
                  <img
                    alt="img"
                    src="img/floor6.png"
                    width="100px"
                    style={{ marginTop: "20px", borderRadius: "20px" }}
                    onClick={(e) => {
                      setState(6);
                    }}
                  ></img>
                  <p
                    style={{
                      color: "white",
                      fontSize: "14px",
                      margin: "2px",
                      marginTop: "10px",
                    }}
                  >
                    {t("Normal Heat Loss room1")}
                  </p>
                  <div
                    className="round"
                    style={{
                      left: "35px",
                      top: "95px",
                      position: "absolute",
                    }}
                  >
                    <input
                      type="radio"
                      id="radio6"
                      checked={state === 6 ? "checked" : ""}
                      name="mode1"
                      onChange={(e) => {
                        if (e.target.checked === true) {
                          setState(6);
                        }
                      }}
                    />
                    <label htmlFor="radio6"></label>
                  </div>
                </div>

                <div style={{ position: "relative", width: "100px" }}>
                  <img
                    alt="img"
                    src="img/floor7.png"
                    width="100px"
                    style={{ marginTop: "20px", borderRadius: "20px" }}
                    onClick={(e) => {
                      setState(7);
                    }}
                  ></img>
                  <p
                    style={{
                      color: "white",
                      fontSize: "14px",
                      margin: "2px",
                      marginTop: "10px",
                    }}
                  >
                    {t("High Heat Loss room2")}
                  </p>
                  <div
                    className="round"
                    style={{
                      left: "35px",
                      top: "95px",
                      position: "absolute",
                    }}
                  >
                    <input
                      type="radio"
                      id="radio7"
                      checked={state === 7 ? "checked" : ""}
                      name="mode1"
                      onChange={(e) => {
                        if (e.target.checked === true) {
                          setState(7);
                        }
                      }}
                    />
                    <label htmlFor="radio7"></label>
                  </div>
                </div>
              </div>
              <div
                style={{
                  width: "220px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ position: "relative", width: "100px" }}>
                  <img
                    alt=""
                    src="img/floor8.png"
                    width="100px"
                    style={{ marginTop: "20px", borderRadius: "20px" }}
                    onClick={(e) => {
                      setState(8);
                    }}
                  ></img>
                  <p
                    style={{
                      color: "white",
                      fontSize: "14px",
                      margin: "2px",
                      marginTop: "10px",
                    }}
                  >
                    {t("Normal Heat Loss room3")}
                  </p>
                  <div
                    className="round"
                    style={{
                      left: "35px",
                      top: "95px",
                      position: "absolute",
                    }}
                  >
                    <input
                      type="radio"
                      id="radio8"
                      checked={state === 8 ? "checked" : ""}
                      name="mode1"
                      onChange={(e) => {
                        if (e.target.checked === true) {
                          setState(8);
                        }
                      }}
                    />
                    <label htmlFor="radio8"></label>
                  </div>
                </div>

                <div style={{ position: "relative", width: "100px" }}>
                  <img
                    alt="alt"
                    src="img/floor9.png"
                    width="100px"
                    style={{ marginTop: "20px", borderRadius: "20px" }}
                    onClick={(e) => {
                      setState(9);
                    }}
                  ></img>
                  <p
                    style={{
                      color: "white",
                      fontSize: "14px",
                      margin: "2px",
                      marginTop: "10px",
                    }}
                  >
                    {t("High Heat Loss room")}
                  </p>
                  <div
                    className="round"
                    style={{
                      left: "35px",
                      top: "95px",
                      position: "absolute",
                    }}
                  >
                    <input
                      type="radio"
                      id="radio9"
                      checked={state === 9 ? "checked" : ""}
                      name="mode1"
                      onChange={(e) => {
                        if (e.target.checked === true) {
                          setState(9);
                        }
                      }}
                    />
                    <label htmlFor="radio9"></label>
                  </div>
                </div>
              </div>
            </RadionBtnBlock>
            <RadionBtnBlock
              header={
                <div style={{ color: "#FFB800", marginTop: "10px" }}>
                  {t("Other floor options")}
                </div>
              }
              style={{ width: "840px" }}
            >
              <div style={{ position: "relative", width: "100px" }}>
                <img
                  alt="img"
                  src="img/floor1.png"
                  width="100px"
                  style={{ marginTop: "20px", borderRadius: "20px" }}
                  onClick={(e) => {
                    setState(1);
                  }}
                ></img>
                <p
                  style={{
                    color: "white",
                    fontSize: "14px",
                    margin: "2px",
                    marginTop: "10px",
                  }}
                >
                  {t("Laminate")}
                </p>
                <div
                  className="round"
                  style={{ left: "35px", top: "95px", position: "absolute" }}
                >
                  <input
                    type="radio"
                    id="radio1"
                    checked={state === 1 ? "checked" : ""}
                    name="mode"
                    onChange={(e) => {
                      if (e.target.checked === true) {
                        setState(1);
                      }
                    }}
                  />
                  <label htmlFor="radio1"></label>
                </div>
              </div>

              <div style={{ position: "relative", width: "100px" }}>
                <img
                  alt=""
                  src="img/floor2.png"
                  width="100px"
                  style={{ marginTop: "20px", borderRadius: "20px" }}
                  onClick={(e) => {
                    setState(2);
                  }}
                ></img>
                <p
                  style={{
                    color: "white",
                    fontSize: "14px",
                    margin: "2px",
                    marginTop: "10px",
                  }}
                >
                  {t("Engineered board")}
                </p>
                <div
                  className="round"
                  style={{ left: "35px", top: "95px", position: "absolute" }}
                >
                  <input
                    type="radio"
                    id="radio2"
                    checked={state === 2 ? "checked" : ""}
                    name="mode"
                    onChange={(e) => {
                      if (e.target.checked === true) {
                        setState(2);
                      }
                    }}
                  />
                  <label htmlFor="radio2"></label>
                </div>
              </div>

              <div style={{ position: "relative", width: "100px" }}>
                <img
                  alt="img"
                  src="img/floor3.png"
                  width="100px"
                  style={{ marginTop: "20px", borderRadius: "20px" }}
                  onClick={(e) => {
                    setState(3);
                  }}
                ></img>
                <p
                  style={{
                    color: "white",
                    fontSize: "14px",
                    margin: "2px",
                    marginTop: "10px",
                  }}
                >
                  {t("Vinyl")}
                </p>
                <div
                  className="round"
                  style={{ left: "35px", top: "95px", position: "absolute" }}
                >
                  <input
                    type="radio"
                    id="radio3"
                    checked={state === 3 ? "checked" : ""}
                    name="mode"
                    onChange={(e) => {
                      if (e.target.checked === true) {
                        setState(3);
                      }
                    }}
                  />
                  <label htmlFor="radio3"></label>
                </div>
              </div>

              <div style={{ position: "relative", width: "100px" }}>
                <img
                  alt="img"
                  src="img/floor4.png"
                  width="100px"
                  style={{ marginTop: "20px", borderRadius: "20px" }}
                  onClick={(e) => {
                    setState(4);
                  }}
                ></img>
                <p
                  style={{
                    color: "white",
                    fontSize: "14px",
                    margin: "2px",
                    marginTop: "10px",
                  }}
                >
                  {t("Carpet")}
                </p>
                <div
                  className="round"
                  style={{ left: "35px", top: "95px", position: "absolute" }}
                >
                  <input
                    type="radio"
                    id="radio4"
                    checked={state === 4 ? "checked" : ""}
                    name="mode"
                    onChange={(e) => {
                      if (e.target.checked === true) {
                        setState(4);
                      }
                    }}
                  />
                  <label htmlFor="radio4"></label>
                </div>
              </div>

              <div style={{ position: "relative", width: "100px" }}>
                <img
                  alt="img"
                  src="img/floor5.png"
                  width="100px"
                  style={{ marginTop: "20px", borderRadius: "20px" }}
                  onClick={(e) => {
                    setState(5);
                  }}
                ></img>
                <p
                  style={{
                    color: "white",
                    fontSize: "14px",
                    margin: "2px",
                    marginTop: "10px",
                  }}
                >
                  {t("Concrete")}
                </p>
                <div
                  className="round"
                  style={{ left: "35px", top: "95px", position: "absolute" }}
                >
                  <input
                    type="radio"
                    id="radio5"
                    checked={state === 5 ? "checked" : ""}
                    name="mode"
                    onChange={(e) => {
                      if (e.target.checked === true) {
                        setState(5);
                      }
                    }}
                  />
                  <label htmlFor="radio5"></label>
                </div>
              </div>
            </RadionBtnBlock>
            <RadionBtnBlock
              header={
                <div style={{ color: "#FFB800", marginTop: "10px" }}>
                  {t("Heating product")}
                </div>
              }
              style={{ width: "700px" }}
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    height: "80px",
                    width: "242px",
                    marginRight: "20px",
                  }}
                >
                  <RadionBtnBlock
                    style={{ width: "100%", padding: "9px" }}
                    styleInner={{
                      height: "40px",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <span
                        style={{
                          color: "#FFB800",
                          marginRight: "26px",
                          fontSize: "18px",
                        }}
                      >
                        {t("Mat")}
                      </span>
                      <div
                        className="round"
                        style={{ top: 0, height: "28px", width: "30px" }}
                      >
                        <input
                          type="radio"
                          id="radio_btn1"
                          checked={floorType === 1 ? "checked" : ""}
                          onChange={(e) => {
                            if (e.target.checked === true) {
                              setFloorType(1);
                            }
                          }}
                        />
                        <label htmlFor="radio_btn1"></label>
                      </div>
                    </div>
                  </RadionBtnBlock>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    height: "80px",
                    width: "387px",
                  }}
                >
                  <RadionBtnBlock
                    style={{
                      width: "100%",
                      padding: "9px",
                    }}
                    styleInner={{
                      height: "40px",
                      alignItems: "center",
                      justifyContent: "none",
                      width: "auto",
                      marginLeft: "20px",
                    }}
                  >
                    <span
                      style={{
                        color: "#FFB800",
                        marginRight: "30px",
                        fontSize: "18px",
                      }}
                    >
                      {t("Cable")}
                    </span>
                    <span
                      style={{
                        color: "white",
                        fontSize: "18px",
                        marginRight: "10px",
                      }}
                    >
                      {t("5 mm")}
                    </span>
                    <div
                      className="round"
                      style={{
                        top: 0,
                        height: "28px",
                        width: "30px",
                        margin: 0,
                        inset: "0 0 0 0",
                      }}
                    >
                      <input
                        type="radio"
                        id="radio_btn2"
                        checked={floorType === 2 ? "checked" : ""}
                        onChange={(e) => {
                          if (e.target.checked === true) {
                            setFloorType(2);
                          }
                        }}
                      />
                      <label htmlFor="radio_btn2"></label>
                    </div>
                    <span
                      style={{
                        color: "white",
                        fontSize: "18px",
                        marginRight: "10px",
                        marginLeft: "32px",
                      }}
                    >
                      {t("3 mm")}
                    </span>
                    <div
                      className="round"
                      style={{
                        top: 0,
                        height: "28px",
                        width: "30px",
                        margin: 0,
                        inset: "0 0 0 0",
                      }}
                    >
                      <input
                        type="radio"
                        id="radio_btn3"
                        checked={floorType === 3 ? "checked" : ""}
                        onChange={(e) => {
                          if (e.target.checked === true) {
                            setFloorType(3);
                          }
                        }}
                      />
                      <label htmlFor="radio_btn3"></label>
                    </div>
                  </RadionBtnBlock>
                </div>
              </div>
            </RadionBtnBlock>
          </div>
        </div>
      </div>
      <Panel page="4" />
      <TipBox tip="4" next={page + "&thermo=1"} prev={page + "&spot=1"} />
    </>
  );
}

export default FloorType;
