var store = {
polygon: null,
poly_type: null,
spots: [],
thermo: [],
empty: function(){
    this.polygon = null;
    this.poly_type = null;
    this.spots = [];
    this.thermo = [];
}
};

export default store;