import TipBox from "../Common/TipBox";
import Panel from "../Common/Panel";
import store from "../Common/Store";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
function SelectPolygon() {
  const { t, i18n } = useTranslation();
  const [state, setState] = useState(1);

  useEffect(() => {
    store.empty();
    i18n.changeLanguage(localStorage.getItem("lang"));
  }, [i18n.language]);

  return (
    <div>
      <div>
        <h1
          style={{
            color: "#5F5F5F",
            fontSize: "24px",
            fontWeight: 400,
            textAlign: "center",
            top: "-500px",
            bottom: 0,
            left: 0,
            right: 0,
            position: "absolute",
            margin: "auto",
            height: "40px",
            paddingLeft: "140px",
          }}
        >
          {t("Choose form")}
        </h1>
        <div
          style={{
            width: "70vw",
            minWidth: "320px",
            maxWidth: "700px",
            height: "500px",
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            top: 0,
            margin: "auto",
            paddingLeft: "140px",
          }}
        >
          <div
            style={{
              display: "inline-flex",
              justifyContent: "center",
              flexWrap: "wrap",
            }}
          >
            <div
              style={{
                margin: "15px",
                width: "200px",
                height: "240px",
                display: "inline-block",
              }}
            >
              <div>
                <img
                  alt="img"
                  src="img/Square.png"
                  width="80%"
                  style={{ marginTop: "50px" }}
                  onClick={() => {
                    setState(1);
                  }}
                ></img>

                <p
                  style={{
                    color: "white",
                    fontSize: "14px",
                    margin: "2px",
                    marginTop: "15px",
                    marginBottom: "15px",
                  }}
                >
                  {t("Rectangle form")}
                </p>
                <div className="round" style={{ left: "90px" }}>
                  <input
                    type="radio"
                    id="radio1"
                    checked={state === 1 ? "checked" : ""}
                    name="mode"
                    onChange={(e) => {
                      if (e.target.checked === true) {
                        setState(1);
                      }
                    }}
                  />
                  <label htmlFor="radio1"></label>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "inline-flex",
              justifyContent: "center",
              flexWrap: "wrap",
            }}
          >
            <div
              style={{
                margin: "15px",
                width: "210px",
                height: "240px",
                display: "inline-block",
              }}
            >
              <div>
                <img
                  alt="img"
                  src="img/L.png"
                  width="80%"
                  style={{ marginTop: "50px" }}
                  onClick={() => {
                    setState(2);
                  }}
                ></img>

                <p
                  style={{
                    color: "white",
                    fontSize: "14px",
                    margin: "2px",
                    marginTop: "15px",
                    marginBottom: "15px",
                  }}
                >
                  {t("L-shaped")}
                </p>
                <div className="round" style={{ left: "90px" }}>
                  <input
                    type="radio"
                    id="radio2"
                    checked={state === 2 ? "checked" : ""}
                    name="mode"
                    onChange={(e) => {
                      if (e.target.checked === true) {
                        setState(2);
                      }
                    }}
                  />
                  <label htmlFor="radio2"></label>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "inline-flex",
              justifyContent: "center",
              flexWrap: "wrap",
            }}
          >
            <div
              style={{
                margin: "15px",
                width: "200px",
                height: "240px",
                display: "inline-block",
              }}
            >
              <div>
                <img
                  alt="img"
                  src="img/T.png"
                  width="80%"
                  style={{ marginTop: "50px" }}
                  onClick={() => {
                    setState(3);
                  }}
                ></img>

                <p
                  style={{
                    color: "white",
                    fontSize: "14px",
                    margin: "2px",
                    marginTop: "15px",
                    marginBottom: "15px",
                  }}
                >
                  {t("T-shaped")}
                </p>
                <div className="round" style={{ left: "90px" }}>
                  <input
                    type="radio"
                    id="radio3"
                    checked={state === 3 ? "checked" : ""}
                    name="mode"
                    onChange={(e) => {
                      if (e.target.checked === true) {
                        setState(3);
                      }
                    }}
                  />
                  <label htmlFor="radio3"></label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Panel page="1" />
      <TipBox tip="1" next={"/standartmode?fig_type=" + state} prev="/mode" />
    </div>
  );
}

export default SelectPolygon;
