import Canva from "../Canva/Canva";
import Konva from "../Canva/Konva";
import Polygon from "../Canva/Polygon";

const funcNext = (
  loading,
  setLoading,
  stateCanva,
  store,
  setError,
  navigate,
  mode
) => {
  if (loading === 0) {
    const room_area = store.polygon
      ? Polygon.getAreaByShoelaceFormula(store.polygon.points)
      : 0;
    if (
      (room_area <= 20 && (store.floorType === 2 || store.floorType === 3)) ||
      store.floorType === 1
    ) {
      if (store.thermo.length > 0) {
        function generateCirclePoints(originX, originY, radius, numPoints) {
          const points = [];

          for (let i = 0; i < numPoints; i++) {
            const angle = ((2 * Math.PI) / numPoints) * i;
            const x = originX + radius * Math.cos(angle);
            const y = originY + radius * Math.sin(angle);
            const ix = originX - radius * Math.cos(angle);
            const iy = originY - radius * Math.sin(angle);
            points.push([ix, iy, x, y]);
          }

          return points;
        }
        function chunkArray(array, chunkSize) {
          const chunkedArray = [];

          for (let i = 0; i < array.length; i += chunkSize) {
            const chunk = array.slice(i, i + chunkSize);
            chunkedArray.push(chunk);
          }

          return chunkedArray;
        }
        const centerX = store.thermo[0].x();
        const centerY = store.thermo[0].y();
        const circleRadius = (Polygon.scale / 100) * 15;
        const numberOfPoints = 10;

        const thermostatsRays = generateCirclePoints(
          centerX,
          centerY,
          circleRadius,
          numberOfPoints
        );

        let isMatching = false;

        stateCanva.stage.find(".cold_spot").forEach(function (spot) {
          spot.fill("#5A659A");
          const points = spot.points();

          points.push(points[0]);
          points.push(points[1]);

          let linesArray = [];

          for (let i = 0; i < points.length; i += 2) {
            if (points[i + 3]) {
              linesArray.push([
                points[i],
                points[i + 1],
                points[i + 2],
                points[i + 3],
              ]);
            } else {
              break;
            }
          }

          thermostatsRays.forEach((thermostatsRay) => {
            linesArray.forEach((chunk) => {
              // stateCanva.layer2.add(
              //   new Konva.Line({
              //     points: [
              //       thermostatsRay[0],
              //       thermostatsRay[1],
              //       thermostatsRay[2],
              //       thermostatsRay[3],
              //     ],
              //     stroke: "green",
              //     strokeWidth: 5,
              //   })
              // );
              // stateCanva.layer2.add(
              //   new Konva.Line({
              //     points: [
              //       spot.x() + chunk[0],
              //       spot.y() + chunk[1],
              //       spot.x() + chunk[2],
              //       spot.y() + chunk[3],
              //     ],
              //     fill: "purple",
              //     stroke: "red",
              //     strokeWidth: 5,
              //   })
              // );

              if (
                Canva.intersects(
                  thermostatsRay[0],
                  thermostatsRay[1],
                  thermostatsRay[2],
                  thermostatsRay[3],
                  spot.x() + chunk[0],
                  spot.y() + chunk[1],
                  spot.x() + chunk[2],
                  spot.y() + chunk[3]
                )
              ) {
                spot.fill("red");
                isMatching = true;
              } else {
              }
            });
          });
        });

        if (!isMatching) {
          setLoading(1);
          stateCanva.cleanPolygon();
          var sum_spot_area = 0;
          const spots = store.spots.map((itm) => {
            const area = Polygon.getAreaByShoelaceFormula(itm.points);
            sum_spot_area += area;
            return {
              spot_point: itm.getPointsCoords(),
              spot_area: area,
              spot_shift: { x: itm.x, y: itm.y },
              type: itm.type,
            };
          });

          const thermo = store.thermo.map((itm) => {
            return {
              coord: { x: itm.x(), y: itm.y(), id: itm.attrs.id },
            };
          });

          const dataF = {
            productType:
              store.floorType === 2 || store.floorType === 3 ? "cable" : "mat",
            room_points: store.polygon.getPointsCoords(),
            room_shift: { x: store.polygon.x, y: store.polygon.y },
            room_heated_area: room_area - sum_spot_area,
            floor1: store.floor1,
            spots: spots,
            thermostats: thermo,
            pixels_per_meter: Canva.scale,
            new_algorithm:
              localStorage.getItem("calc_type") === "true" ? true : false,
          };

          if (store.floorType === 2) {
            dataF.cableType = "t5mm";
          } else if (store.floorType === 3) {
            dataF.cableType = "t3mm";
          }

          fetch(process.env.REACT_APP_BE_HOST + "/materials", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify(dataF),
          })
            .then((response) => {
              if (response.ok) {
                return response.json();
              } else {
                setLoading(0);
                setError(1);

                setTimeout(() => {
                  setError(0);
                }, 1500);
              }
            })
            .then((resp, rej) => {
              if (resp) {
                if ("calculatedData" in resp) {
                  const line_colors = ["red", "orange", "yellow"];

                  resp.calculatedData.wires.forEach((elm, i) => {
                    let electric_cable = null;

                    if (resp.calculatedData.wires[i].wire_points) {
                      if (resp.calculatedData.wires[i].wire_points.length > 6) {
                        electric_cable = [
                          resp.calculatedData.wires[i].wire_points.shift(),
                          resp.calculatedData.wires[i].wire_points.shift(),
                          resp.calculatedData.wires[i].wire_points.shift(),
                          resp.calculatedData.wires[i].wire_points.shift(),
                          resp.calculatedData.wires[i].wire_points[0],
                          resp.calculatedData.wires[i].wire_points[1],
                        ];
                      }
                    }

                    if (electric_cable) {
                      const e_line = new Konva.Line({
                        x: 0,
                        y: 0,
                        points: electric_cable,
                        stroke: "grey",
                        tension: 0.05,
                        name: "wire",
                      });
                      stateCanva.layer1.add(e_line);
                    }

                    const line = new Konva.Line({
                      x: 0,
                      y: 0,
                      points: resp.calculatedData.wires[i].wire_points,
                      stroke: line_colors[i] ? line_colors[i] : "red",
                      tension: 0.05,
                      name: "wire",
                    });

                    stateCanva.layer1.add(line);
                  });

                  const stagePoly = stateCanva.stage.find(".poly");
                  const stageThemo = stateCanva.stage.find(".thermo");
                  const stageSpot = stateCanva.stage.find(".cold_spot");
                  const stageWire = stateCanva.stage.find(".wire");
                  const stageRef = stateCanva.stage.find(".ref_point");

                  const group = new Konva.Group();

                  stagePoly.forEach((itm) => {
                    group.add(itm);
                  });
                  stageSpot.forEach((itm) => {
                    group.add(itm);
                  });
                  stageWire.forEach((itm) => {
                    group.add(itm);
                  });
                  stageRef.forEach((itm) => {
                    group.add(itm);
                  });
                  stageThemo.forEach((itm) => {
                    group.add(itm);
                  });

                  store.final_img = group.toDataURL({ pixelRatio: 10 });
                  store.mat_data = resp;

                  const img_final = new Image();

                  img_final.onload = () => {
                    store.final_img_w = img_final.width;
                    store.final_img_h = img_final.height;
                    navigate("/result?mode=" + mode);
                  };

                  img_final.src = store.final_img;
                }
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          alert("Thermostat is too close to the cold spot.");
        }
      } else {
        alert("Choose thermostat to continue");
      }
    } else {
      alert(
        "The size of the room exceeds 20 square meters. Please proceed to the selection of heating mats."
      );
    }
  }
};

export default funcNext;
