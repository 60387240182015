function RadioBtnBlock(props) {
  return (
    <div
      style={{
        overflow: "hidden",
        margin: "auto",
        marginTop: "10px",
        padding: "4px 0 10px 0",
        background:
          "linear-gradient(138.31deg, rgba(255, 255, 255, 0.1) -0.24%, rgba(30, 36, 62, 0.1) 123.16%)",
        backdropFilter: "blur(50px)",
        borderRadius: "30px",
        border: "1px solid",
        borderImageSource:
          "linear-gradient(119.15deg, rgba(255, 255, 255, 0.37) -0.5%, rgba(255, 255, 255, 0) 104.16%)",
        ...props.style,
      }}
    >
      {props.header && (
        <div
          style={{
            color: "#FFB800",
            marginTop: "10px",
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          {props.header}
        </div>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          width: "90%",
          margin: "auto",
          ...props.styleInner,
        }}
      >
        {props.children}
      </div>
    </div>
  );
}

export default RadioBtnBlock;
