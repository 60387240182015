import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Canva from "../Canva/Canva";
import Polygon from "../Canva/Polygon";
import store from "../Common/Store";

function SlideBar(props) {
  const [stateAngles, setStateAngles] = useState(false);
  const [stateLabels, setStateLabels] = useState(false);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (props.access) {
      setStateAngles(props.access.angles);
      setStateLabels(props.access.labels);
    }
  }, [props.access.angles, props.access.labels]);

  const erase = function () {
    if (props.erase) {
      return (
        <div
          onClick={() => {
            props.canva.deleteDraw(true);
            store.empty();
            if (props.eraseFunc) {
              props.eraseFunc();
            }
          }}
          className="erase"
        >
          <span style={{ display: "inline-block", marginTop: "4px" }}>
            {t("Erase")}
          </span>
        </div>
      );
    } else {
      return "";
    }
  };

  return (
    <>
      <div className="slideBar">
        {erase()}
        <div style={{ width: "380px" }}>
          <div className="slider_wrap">
            <span
              className="slider_text"
              style={{ fontSize: "14px", marginLeft: "10px", marginTop: "6px" }}
            >
              {t("Angles")}
            </span>
            <label className="switch">
              <input
                type="checkbox"
                checked={stateAngles === true ? "checked" : ""}
                onChange={(e) => {
                  props.access.angles = e.target.checked;
                  setStateAngles(props.access.angles);
                  if (e.target.checked) {
                    props.access.showAnglesButton(props.canva);
                  } else {
                    props.access.hideAnglesButton(props.canva);
                  }
                }}
              />
              <span className="slider round"></span>
            </label>
          </div>
          <div className="slider_wrap">
            <span
              className="slider_text"
              style={{ fontSize: "14px", marginLeft: "12px", marginTop: "6px" }}
            >
              {t("Labels")}
            </span>
            <label className="switch">
              <input
                type="checkbox"
                checked={stateLabels === true ? "checked" : ""}
                onChange={(e) => {
                  props.access.labels = e.target.checked;
                  localStorage.setItem("hm_labels", props.access.labels);
                  setStateLabels(props.access.labels);
                  if (e.target.checked) {
                    props.access.showLabels(props.canva);
                  } else {
                    props.access.hideLabels(props.canva);
                  }
                }}
              />
              <span className="slider round"></span>
            </label>
          </div>
          <div className="slider_wrap">
            <span
              className="slider_text"
              style={{ fontSize: "14px", marginLeft: "16px", marginTop: "6px" }}
            >
              {t("Scale")}
            </span>
            <label className="switch">
              <span className="slider_empty round">
                <span
                  className="plus_minus"
                  style={{ top: "-6px" }}
                  onClick={() => {
                    var poly;
                    if (!props.poly) {
                      poly = props.canva.lastPolygon;
                    } else {
                      poly = props.poly;
                    }
                    if (Canva.scale < Canva.baseScale && Canva.scale >= 1) {
                      Canva.scale += 1;
                      Polygon.scale += 1;
                    } else if (
                      Canva.scale < Canva.baseScale &&
                      Canva.scale > 0
                    ) {
                      Canva.scale += 0.1;
                      Polygon.scale += 0.1;
                    }
                    if (poly) {
                      Canva.polyDragFunc(
                        props.canva,
                        poly,
                        props.access,
                        false
                      )({ x: poly.x, y: poly.y }, { x: poly.x, y: poly.y });
                    }
                    if (props.func) {
                      props.func();
                    }
                  }}
                >
                  -
                </span>

                <span
                  className="plus_minus"
                  style={{ top: "-6px" }}
                  onClick={() => {
                    var poly;
                    if (!props.poly) {
                      poly = props.canva.lastPolygon;
                    } else {
                      poly = props.poly;
                    }

                    if (Canva.scale > 1) {
                      Canva.scale -= 1;
                      Polygon.scale -= 1;
                    } else if (Canva.scale > 0.2) {
                      Canva.scale -= 0.1;
                      Polygon.scale -= 0.1;
                    }
                    if (poly) {
                      Canva.polyDragFunc(
                        props.canva,
                        poly,
                        props.access,
                        false
                      )({ x: poly.x, y: poly.y }, { x: poly.x, y: poly.y });
                    }
                    if (props.func) {
                      props.func();
                    }
                  }}
                >
                  +
                </span>
              </span>
            </label>
          </div>
        </div>
      </div>
      <div className="infoText">
        <div
          style={{
            color: "gray",
            fontSize: "10px",
            padding: "2px 8px",
            textAlign: "center",
          }}
        >
          To add additional corners to the room using the "Angles" mode:
          <br />
          Activate "Angles" mode in the room editor. <br />
          Click on the white dot that appears to create a new corner. <br />
          Click on the red dot to revert back to the initial angle.
        </div>
      </div>
    </>
  );
}

export default SlideBar;
