import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Canva from "../Canva/Canva";

import { Link as RoutLink, useNavigate } from "react-router-dom";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
  Image,
  PDFViewer,
  Link,
} from "@react-pdf/renderer";
import { Font } from "@react-pdf/renderer";

Font.register({
  family: "Montserrat-SemiBold",
  src: "/fonts/Montserrat-SemiBold.ttf",
});
Font.register({
  family: "Montserrat-Regular",
  src: "/fonts/Montserrat-Regular.ttf",
});

const floorText = {
  1: "Laminate/ engineered board floating",
  2: "Engineered board non-floating",
  3: "Vinyl/LVT",
  4: "Carpet",
  5: "Concrete",
  6: "Normal Heat Loss room",
  7: "Normal Heat Loss room",
  8: "Normal Heat Loss room",
  9: "High Heat Loss room",
};

const storeGlob = localStorage.getItem("store")
  ? JSON.parse(localStorage.getItem("store"))
  : null;

const Br = () => "\n";
const MyDocument = ({ store = storeGlob }) => {
  const styles = StyleSheet.create({
    page: {
      // flexDirection: "row",
      //  backgroundColor: "#E4E4E4",
    },
    section_header: {
      flexGrow: 1,
      height: "200px",
      overflow: "hidden",
    },
    section_header_text: {
      position: "absolute",
      right: "7%",
      top: "12%",
    },
    image_header_line: {
      position: "absolute",
      height: "20px",
    },
    header_text_0: {
      fontFamily: "Montserrat-SemiBold",
      fontSize: "10px",
      color: "white",
      marginTop: "1px",
      position: "absolute",
      top: "3px",
      left: "27px",
    },
    header_text_1: {
      fontFamily: "Montserrat-SemiBold",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "10px",
      color: "white",
      marginTop: "1px",
    },
    header_text_2: {
      fontFamily: "Montserrat-SemiBold",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "10px",
      color: "white",
      marginTop: "8px",
    },

    header_text_3: {
      fontFamily: "Montserrat-SemiBold",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "10px",
      color: "white",
      marginTop: "8px",
    },
    header_text_4: {
      fontFamily: "Montserrat-SemiBold",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "10px",
      color: "white",
      marginTop: "6px",
    },
    section_content: {
      //   margin: 10,
      padding: 10,
      height: "615px",
    },
    section_footer: {
      //   margin: 10,
    },
    section_header_2: {
      flexGrow: 1,
      height: "38px",
      overflow: "hidden",
    },
    section_content_2: {
      padding: 10,

      height: "780px",
    },
    section_footer_2: {
      //   margin: 10,
    },
    image: {
      width: "100%",
    },
    image_info: {
      width: "160px",
      position: "absolute",
      right: "8%",
      top: "12%",
    },
    image_labels_1: {
      width: "180px",
      position: "absolute",
      right: "2%",
      top: "78%",
    },
    image_labels_2: {
      width: "180px",
      position: "absolute",
      right: "0%",
      left: "50%",
      marginLeft: "-90px",
      top: "97%",
    },
    image_footer: {
      width: "100%",
      position: "absolute",
    },
    image_footer_info: {
      width: "90%",
      position: "absolute",
      marginTop: "8px",
      marginLeft: "5%",
    },
    image_scheme: {
      marginTop:
        store.final_img_w > store.final_img_h
          ? (350 - Math.round(350 / (store.final_img_w / store.final_img_h))) /
              2 +
            "px"
          : 0,
      ...(store.final_img_w >= store.final_img_h
        ? { width: "350px", height: "auto" }
        : {
            width: store.final_img_w
              ? 300 * (store.final_img_w / store.final_img_h) + "px"
              : 0,
            height: "300px",
          }),
      padding: 10,
      marginLeft:
        store.final_img_w >= store.final_img_h
          ? "-175px"
          : store.final_img_w
          ? -(300 * (store.final_img_w / store.final_img_h)) / 2 + "px"
          : 0,
      left: "50%",
    },
    text_1: {
      fontFamily: "Montserrat-SemiBold",
      fontSize: "14px",
      fontWeight: "bold",
      textAlign: "center",
      marginBottom: "10px",
    },
    text_2: {
      fontFamily: "Montserrat-Regular",
      fontSize: "12px",
      textAlign: "center",
      marginBottom: "10px",
      padding: "0px 60px",
    },
    text_3: {
      fontFamily: "Montserrat-SemiBold",
      marginTop: "100px",
      fontSize: "12px",
      color: "#EE7402",
      marginBottom: "4px",
      position: "absolute",
      margin: "10px 20px",
      top: "500px",
    },
    text_4: {
      fontFamily: "Montserrat-Regular",
      fontSize: "12px",
      backgroundColor: "#F4F4F4",
      padding: "8px",
      borderRadius: "4px",
      position: "absolute",
      width: "100%",
      margin: "10px 20px",
      top: "520px",
    },

    text_5: {
      fontSize: "10px",
      padding: "2px 6px",
      fontFamily: "Montserrat-Regular",
    },

    text_5_wrap: {
      display: "flex",
      flexDirection: "row",
    },

    text_5_wrap_2: {
      backgroundColor: "#F4F4F4",
      padding: "2px 0",
      borderRadius: "4px",
      margin: "0px 10px",
    },

    text_6: {
      fontSize: "12px",
      fontFamily: "Montserrat-SemiBold",
      padding: "8px",
      borderRadius: "4px",
    },
    text_7: {
      fontSize: "12px",
      fontFamily: "Montserrat-SemiBold",
      padding: "8px",
      borderRadius: "4px",
    },
    text_8: {
      fontSize: "11px",
      maxHeight: "300px",
      overflow: "hidden",
      padding: "8px",
      borderRadius: "4px",
      fontFamily: "Montserrat-Regular",
    },
    text_9: {
      fontSize: "12px",
      fontFamily: "Montserrat-SemiBold",
      padding: "8px",
      borderRadius: "4px",
    },
    text_10: {
      fontSize: "11px",
      fontFamily: "Montserrat-Regular",
      padding: "8px",
      borderRadius: "4px",
      overflow: "hidden",
      maxHeight: "100px",
    },
    text_11: {
      fontSize: "12px",
      backgroundColor: "#F6BE2B",
      fontFamily: "Montserrat-Regular",
      padding: "2px 10px",
      margin: "0px 10px",
      borderRadius: "4px",
    },
    text_12: {
      fontFamily: "Montserrat-Regular",
      fontSize: "12px",
      padding: "8px",
      borderRadius: "4px",
    },
    text_13: {
      fontFamily: "Montserrat-Regular",
      fontSize: "12px",
      padding: "8px",
      borderRadius: "4px",
    },
    text_14: {
      fontFamily: "Montserrat-SemiBold",
      fontSize: "12px",
      padding: "4px 8px",
      borderRadius: "4px",
    },
    text_15: {
      fontSize: "12px",
      fontFamily: "Montserrat-Regular",
      padding: "4px 8px",
      borderRadius: "4px",
    },
    text_16: {
      fontFamily: "Montserrat-SemiBold",
      fontSize: "12px",
      color: "#EE7402",
      margin: "4px 10px",
    },
    text_div: {
      height: "10px",
    },
    next_page_img: {
      left: "50%",
      height: "10px",
      width: "10px",
      marginTop: "-15px",
      marginLeft: "-5px",
    },
    header_arrow: {
      position: "absolute",
      right: "10px",
      top: "10px",
      width: "12px",
    },
    text_underline: {
      borderBottom: "1px solid dotted",
      borderStyle: "dotted",
      height: "12px",
      display: "flex",
      flexGrow: 1,
    },
    head_link: {
      height: "25px",
      width: "150px",
      position: "absolute",
      right: "1%",
      top: "8%",
    },
    strong_text: {
      fontFamily: "Montserrat-SemiBold",
    },
    strong_text_link: {
      fontFamily: "Montserrat-SemiBold",
      color: "black",
    },
    info_img: {
      width: "10px",
      height: "10px",
      position: "absolute",
      top: "12px",
      left: "4px",
    },
  });

  const room_area = store.room_area;

  const spots = store.spotsPrep;

  const thermo = store.thermoPrep;
  var data = {};

  if (store.polygon) {
    data = {
      room_points: store.polygon.getPointsCoordsPrep,
      room_side_length: Canva.getSidesLength([
        ...store.polygon.points,
        store.polygon.points[0],
      ]),
      room_side_center: Canva.getSidesCenters([
        ...store.polygon.points,
        store.polygon.points[0],
      ]),
      room_shift: { x: store.polygon.x, y: store.polygon.y },
      room_full_area: room_area,
      floor1: store.floor1,
      spots: spots,
      thermostats: thermo,
      pixels_per_meter: Canva.scale,
    };
    //console.log(data);
  }
  const jsx_arr1 = [];
  const jsx_arr2 = [];

  if (store.mat_data) {
    let text_arr = store.mat_data.chosenMatDescription.matDesc.match(
      /<Link (.*?)>(.*?)<\/Link>/gi
    );
    if (text_arr === null) {
      text_arr = [store.mat_data.chosenMatDescription.matDesc];
    } else {
      text_arr = text_arr.map((elm) => {
        return elm.split(">")[1].split("<")[0];
      });
    }

    let src_arr = store.mat_data.chosenMatDescription.matDesc.match(
      /<Link (.*?)>(.*?)<\/Link>/gi
    );
    if (src_arr === null) {
      src_arr = [];
    } else {
      src_arr = src_arr.map((elm) => {
        return elm.split('"')[1];
      });
    }

    const clean_text_arr1 = store.mat_data.chosenMatDescription.matDesc.replace(
      /<Link (.*?)>(.*?)<\/Link>/gi,
      "<a></a>"
    );
    const clean_text_arr2 = clean_text_arr1.split(/<[^>]*>/gi).filter((elm) => {
      return elm.length > 0;
    });

    clean_text_arr2.forEach((itm, i) => {
      jsx_arr1.push(itm);
      if (src_arr[i]) {
        jsx_arr1.push(
          <Link src={src_arr[i]} key={i}>
            <Text style={styles.strong_text_link}>{text_arr[i]}</Text>
          </Link>
        );
      } else {
        <Text style={styles.strong_text_link}>{text_arr[i]}</Text>;
      }
    });

    if (store.mat_data.chosenMatDescription.addDesc) {
      let text_arr_add = store.mat_data.chosenMatDescription.addDesc.match(
        /<Link (.*?)>(.*?)<\/Link>/gi
      );

      if (text_arr_add === null) {
        text_arr_add = [store.mat_data.chosenMatDescription.addDesc];
      } else {
        text_arr_add = text_arr_add.map((elm) => {
          return elm.split(">")[1].split("<")[0];
        });
      }

      let src_arr_add = store.mat_data.chosenMatDescription.addDesc.match(
        /<Link (.*?)>(.*?)<\/Link>/gi
      );

      if (src_arr_add === null) {
        src_arr_add = [];
      } else {
        src_arr_add = src_arr_add.map((elm) => {
          return elm.split('"')[1];
        });
      }

      const clean_text_arr1_add =
        store.mat_data.chosenMatDescription.addDesc.replace(
          /<Link (.*?)>(.*?)<\/Link>/gi,
          "<a></a>"
        );
      const clean_text_arr2_add = clean_text_arr1_add
        .split(/<[^>]*>/gi)
        .filter((elm) => {
          return elm.length > 0;
        });

      clean_text_arr2_add.forEach((itm, i) => {
        jsx_arr2.push(itm);
        if (src_arr_add[i]) {
          jsx_arr2.push(
            <Link src={src_arr_add[i]} key={i}>
              <Text style={styles.strong_text_link}>{text_arr_add[i]}</Text>
            </Link>
          );
        } else {
          <Text style={styles.strong_text_link}>{text_arr_add[i]}</Text>;
        }
      });
    }
  }

  const mats = {};
  if (store.mat_data) {
    Object.values(store.mat_data.matParams).forEach((elm) => {
      if (mats[elm.MatId] === undefined) {
        mats[elm.MatId] = [elm];
      } else {
        mats[elm.MatId].push(elm);
      }
    });
  }
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View>
          <View style={styles.section_header}>
            <Image
              src={
                storeGlob.floorType === 1
                  ? "/img/pdf_header.png"
                  : "/img/pdf_header2.png"
              }
              style={styles.image}
            ></Image>
            <Image src="/img/header_info.png" style={styles.image_info}></Image>
            <Image
              src="/img/header_arrow.png"
              style={styles.header_arrow}
            ></Image>
            <Image
              src="/img/pdf_labels.png"
              style={styles.image_labels_1}
            ></Image>
            <Image
              src="/img/pdf_footer.png"
              style={styles.image_header_line}
            ></Image>
            <Text style={styles.header_text_0}>
              Underfloor heating — made easy...
            </Text>
            <View style={styles.section_header_text}>
              <Text style={styles.header_text_1}>heatmat.co.uk</Text>
              <Text style={styles.header_text_2}>01444 247020</Text>
              <Text style={styles.header_text_3}>tech@heatmat.co.uk</Text>
              <Text style={styles.header_text_4}>
                3 Danworth Farm, Cuckfield <Br /> Road, Hurstpierpoint, West{" "}
                <Br /> Sussex, BN6 9GL
              </Text>
            </View>
          </View>

          <View style={styles.section_content}>
            <Text style={styles.text_1}>Dear Customer</Text>
            <Text style={styles.text_2}>
              Thank you for using our underfloor heating layout tool. The
              diagram below should help you install your underfloor heating in a
              logical way however please remember that this is for guidance
              only, and you may find a simpler way to layout your heating system
              on site. Please contact Heat Mat technical support or read the
              installation instructions that came with your product for more
              information
            </Text>
            {store.final_img ? (
              <Image src={store.final_img} style={styles.image_scheme}></Image>
            ) : (
              ""
            )}
            <Text style={styles.text_3}>Room configuration</Text>
            <Text style={styles.text_4}>
              Room Area:{" "}
              <Text style={styles.strong_text}>{data.room_full_area} м²</Text> |
              Heated Area:{" "}
              <Text style={styles.strong_text}>
                {store.mat_data
                  ? store.mat_data.props
                    ? store.mat_data.props.TotalMatCoverage
                    : "---"
                  : "---"}{" "}
                м²
              </Text>
              <Br />
              Floor Type:{" "}
              <Text style={styles.strong_text}>{floorText[data.floor1]}</Text>
              <Br /> <Br />
            </Text>
          </View>

          <View style={styles.section_footer}>
            <Image
              src="/img/next_page.png"
              style={styles.next_page_img}
            ></Image>
            <Image
              src="/img/pdf_footer.png"
              style={styles.image_footer}
            ></Image>
            <Image
              src="/img/footer_info.png"
              style={styles.image_footer_info}
            ></Image>
            <View
              style={{
                marginTop: "13px",
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
              }}
            >
              <Link
                email="heatmat.co.uk"
                style={{
                  color: "white",
                  fontSize: "8px",
                  marginLeft: "42px",
                }}
              >
                tech@heatmat.co.uk
              </Link>
              <Text
                style={{
                  color: "white",
                  fontSize: "8px",
                  marginLeft: "30px",
                }}
              >
                01444 247020
              </Text>
              <Text
                style={{
                  color: "white",
                  fontSize: "8px",
                  marginLeft: "38px",
                }}
              >
                3 Danworth Farm, Cuckfield Road, Hurstpierpoint, West Sussex,
                BN6 9GL
              </Text>
              <Link
                src="heatmat.co.uk"
                style={{
                  color: "white",
                  fontSize: "8px",
                  marginLeft: "20px",
                }}
              >
                heatmat.co.uk
              </Link>
            </View>
          </View>
        </View>
      </Page>
      <Page size="A4" style={styles.page}>
        <View>
          <View style={styles.section_header_2}>
            <Image src="/img/pdf_header_p2.png" style={styles.image}></Image>
            <Link src="heatmat.co.uk" style={styles.head_link}></Link>
          </View>
          <View style={styles.section_content_2}>
            <Text style={styles.text_16}>Component list</Text>
            <View style={styles.text_5_wrap_2}>
              {Object.keys(mats).map((item, i) => {
                return (
                  <View style={styles.text_5_wrap} key={i}>
                    <Text style={styles.text_5}>
                      {store.mat_data ? mats[item].length : ""}
                      {" x "}
                      {store.mat_data ? item : ""}
                    </Text>
                    <View style={styles.text_underline}></View>
                    <Text style={styles.text_5}>{mats[item][0].Size}м²</Text>
                    <View
                      style={{
                        ...styles.text_underline,
                        ...{ maxWidth: "6px" },
                      }}
                    ></View>
                    <Text style={styles.text_5}>
                      {mats[item][0].Wattage}
                      W/sqm
                    </Text>
                    <View
                      style={{
                        ...styles.text_underline,
                        ...{ maxWidth: "6px" },
                      }}
                    ></View>
                    <Text style={styles.text_5}>
                      {store.mat_data
                        ? store.mat_data.chosenMatDescription.type
                        : ""}
                    </Text>
                  </View>
                );
              })}

              {store.mat_data
                ? store.mat_data.chosenMatDescription.addDesc &&
                  (store.mat_data.props ? (
                    store.mat_data.props.OverlayBoards ? (
                      <View style={styles.text_5_wrap}>
                        <Text style={styles.text_5}>
                          {store.mat_data.props.OverlayBoards} x
                        </Text>
                        <View style={styles.text_underline}></View>
                        <Text style={styles.text_5}>
                          {store.mat_data
                            ? store.mat_data.chosenMatDescription.type
                            : ""}
                        </Text>
                      </View>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  ))
                : ""}

              <View style={styles.text_5_wrap}>
                <Text style={styles.text_5}>
                  1 x {store.thermoInfo ? store.thermoInfo.name : "thermostat"}
                </Text>
                <View style={styles.text_underline}></View>
                <Text style={styles.text_5}>
                  {store.thermoInfo
                    ? store.thermoInfo.description
                      ? store.thermoInfo.description.substring(0, 40)
                      : "thermostat"
                    : "thermostat"}
                </Text>
              </View>

              {/* {store.mat_data.calculatedData
                ? store.mat_data.calculatedData.isolation
                  ? store.mat_data.calculatedData.isolation.length > 0
                    ? store.mat_data.calculatedData.isolation.map((item) => {
                        return (
                          <View
                            style={styles.text_5_wrap}
                            key={item.type + "_" + Math.random() * 100}
                          >
                            <Text style={styles.text_5}>1 x {item.type}</Text>
                            <View style={styles.text_underline}></View>
                            <Text style={styles.text_5}>
                              {item.common_name}
                            </Text>
                          </View>
                        );
                      })
                    : ""
                  : ""
                : ""} */}
            </View>
            <Text style={styles.text_div}></Text>
            <Text style={styles.text_6}>
              1.{" "}
              {store.mat_data
                ? store.mat_data.chosenMatDescription.matName
                : ""}
            </Text>
            <Text style={styles.text_7}>
              {store.mat_data ? store.mat_data.chosenMatDescription.type : ""}
            </Text>
            <Text style={styles.text_8}>
              {store.mat_data ? jsx_arr1 : ""}

              <Br />
              <Br />

              {store.mat_data ? jsx_arr2 : ""}
            </Text>
            <Text style={styles.text_div}></Text>
            <Text style={styles.text_9}>
              2. {store.thermoInfo ? store.thermoInfo.name : "thermostat"}
            </Text>
            <Text style={styles.text_10}>
              {store.thermoInfo ? store.thermoInfo.description : "..."}
            </Text>

            <Text style={styles.text_div}></Text>
            <View style={styles.text_11}>
              <Image src="/img/info.png" style={styles.info_img}></Image>
              <Text style={styles.text_12}>
                JOIN THE FAMILY OF HEATMAT CUSTOMERS
              </Text>
              <Br />
              <Br />
              <Text style={styles.text_13}>
                <Text style={styles.strong_text}>Heat Mat’s</Text> systems are
                available throughout the UK from more than{" "}
                <Text style={styles.strong_text}>4,000</Text> authorised
                distributors. For details of a distributor local to you please
                either contact Heat Mat on{" "}
                <Text style={styles.strong_text}>01444 247020</Text> or search
                for the components product code online.
              </Text>
            </View>
            <Text style={styles.text_div}></Text>
            <Text style={styles.text_14}>
              IMPORTANT INFORMATION ON THIS SPECIFICATION
            </Text>
            <Text style={styles.text_15}>
              Your specification has been prepared based around a number of
              assumptions and you should review these before you order any
              items. Please follow this{" "}
              <Link src="https://www.heatmat.co.uk/assumptions">
                <Text style={styles.strong_text_link}>link</Text>
              </Link>{" "}
              for full details.
            </Text>
            <Image
              src="/img/pdf_labels.png"
              style={styles.image_labels_2}
            ></Image>
          </View>
          <View style={styles.section_footer_2}>
            <Image
              src="/img/pdf_footer.png"
              style={styles.image_footer}
            ></Image>
            <View
              style={{
                marginTop: "8px",
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
              }}
            >
              <Link
                email="heatmat.co.uk"
                style={{
                  color: "white",
                  fontSize: "8px",
                  marginLeft: "42px",
                }}
              >
                tech@heatmat.co.uk
              </Link>
              <Text
                style={{
                  color: "white",
                  fontSize: "8px",
                  marginLeft: "30px",
                }}
              >
                01444 247020
              </Text>
              <Text
                style={{
                  color: "white",
                  fontSize: "8px",
                  marginLeft: "38px",
                }}
              >
                3 Danworth Farm, Cuckfield Road, Hurstpierpoint, West Sussex,
                BN6 9GL
              </Text>
              <Link
                src="heatmat.co.uk"
                style={{
                  color: "white",
                  fontSize: "8px",
                  marginLeft: "20px",
                }}
              >
                heatmat.co.uk
              </Link>
            </View>

            <Image
              src="/img/footer_info.png"
              style={styles.image_footer_info}
            ></Image>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const PDFPage = () => {
  let navigate = useNavigate();

  if (storeGlob.polygon) {
  } else {
    navigate("/mode");
  }

  const styles = StyleSheet.create({
    viewer: {
      width: window.innerWidth, //the pdf viewer will take up all of the width and height
      height: window.innerHeight,
    },
  });

  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(1);
  useEffect(() => {
    if (document.querySelector("iframe")) {
      document.querySelector("iframe").onload = () => {
        // setLoading(0);
        document.querySelector("#loadingImgWrapper").remove();
      };
    }
  });
  return (
    <div>
      <PDFViewer style={styles.viewer}>
        <MyDocument display="svg" />
      </PDFViewer>
      <div
        id="loadingImgWrapper"
        style={
          loading === 0
            ? { display: "none" }
            : {
                width: "100vw",
                height: "100vh",
                backgroundColor: "#1c1c34",
                position: "fixed",
                inset: "0 0 0 0",
                zIndex: "3",
              }
        }
      >
        <div className="loadingImgWrap">
          <img
            src="./img/loading.svg"
            className="loadingImg"
            alt="loading..."
          ></img>
          <div>
            <span>{t("Processing")}</span>
          </div>
        </div>
      </div>
      <div style={{ display: "none" }}>
        <PDFDownloadLink
          document={<MyDocument />}
          fileName={"HEATMAT_installation_recommendations"}
        >
          <button type="button"> Download PDF</button>
        </PDFDownloadLink>
      </div>
    </div>
  );
};

export default PDFPage;
export { MyDocument };
export function PDFDownload({ PDF, btn, onClick }) {
  return (
    <PDFDownloadLink
      document={PDF}
      fileName={"HEATMAT_installation_recommendations"}
      onClick={onClick}
    >
      {btn}
    </PDFDownloadLink>
  );
}
