import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../Common/i18n";
import Accessories from "../../Canva/Accessories";
import Canva from "../../Canva/Canva";
import Konva from "../../Canva/Konva";
import Point from "../../Canva/Point";
import Polygon from "../../Canva/Polygon";
import { heatAreaEvent } from "../global_helper";
import PanelWrapped from "./PanelWrapped";
import SlideBar from "../../Common/SlideBar";
import store from "../../Common/Store";
import Tip from "./Tip";
import { addSpotReady } from "./helper";

var poly_type = 1;
function ModePolygon() {
  const [stateCanva, setStateC] = useState(null);
  const [statePoly, setStateP] = useState(null);
  const stateAccess = useRef(new Accessories());
  const [stateArea, setStateArea] = useState(0);
  const [stateHeatArea, setStateHeatArea] = useState(0);
  const [stateStep, setStateStep] = useState(1);
  const [stateSpot, setStateSpot] = useState(1);
  const [stateSpotDraw, setStateSpotDraw] = useState(0);
  const [loading, setLoading] = useState(0);
  const [ajax_error, setError] = useState(0);
  const [heatAreaLoad, setHeatAreaLoad] = useState(0);
  const { t } = useTranslation();
  const url_string = window.location.href;
  const url = new URL(url_string);
  const spot = url.searchParams.get("spot");
  const thermostat = parseInt(url.searchParams.get("thermo"));

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem("lang"));

    stateAccess.current.autoBind = true;

    if (localStorage.getItem("hm_labels") !== undefined) {
      if (localStorage.getItem("hm_labels") === "true") {
        stateAccess.current.labels = true;
      } else {
        stateAccess.current.labels = false;
      }
    } else {
      stateAccess.current.labels = true;
    }

    if (spot && stateSpot === 1) {
      if (parseInt(spot) === 1) {
        setStateStep(2);
      }
    }

    if (stateCanva === null) {
      var canva = new Canva(window.innerWidth, window.innerHeight, Konva);
    } else {
      if (store.spots.length > 0 && stateSpotDraw === 0) {
        if (stateCanva.stage.find(".cold_spot").length > 0) {
          stateCanva.stage.find(".cold_spot").forEach((itm) => {
            itm.destroy();
          });
        }
        if (stateCanva.stage.find(".x_point").length > 0) {
          stateCanva.stage.find(".x_point").forEach((itm) => {
            if (itm.attrs.parent_points() !== statePoly.points) {
              itm.getAttr("parent_group").destroy();
            }
          });
        }
        store.spots.forEach((itm) => {
          addSpotReady(itm, stateCanva, stateAccess.current);
        });

        setStateSpotDraw(1);
        heatAreaEvent(stateCanva, setHeatAreaLoad, setStateHeatArea);
      }
    }

    if (statePoly === null) {
      const init_point_x = window.innerWidth / 2 - 200;
      const init_point_y = window.innerHeight / 2 - 150;

      const point1 = new Point(init_point_x, init_point_y);
      const point2 = new Point(init_point_x, init_point_y + 200);
      const point3 = new Point(init_point_x + 200, init_point_y + 200);
      const point4 = new Point(init_point_x + 200, init_point_y + 100);
      const point5 = new Point(init_point_x + 100, init_point_y + 100);
      const point6 = new Point(init_point_x + 100, init_point_y);

      const point7 = new Point(init_point_x, init_point_y);
      const point8 = new Point(init_point_x, init_point_y + 100);
      const point9 = new Point(init_point_x + 100, init_point_y + 100);
      const point10 = new Point(init_point_x + 100, init_point_y);

      const point11 = new Point(init_point_x, init_point_y);
      const point12 = new Point(init_point_x, init_point_y + 200);
      const point13 = new Point(init_point_x + 100, init_point_y + 200);
      const point14 = new Point(init_point_x + 100, init_point_y + 300);
      const point15 = new Point(init_point_x + 200, init_point_y + 300);
      const point16 = new Point(init_point_x + 200, init_point_y + 200);
      const point17 = new Point(init_point_x + 300, init_point_y + 200);
      const point18 = new Point(init_point_x + 300, init_point_y);

      const polygon = new Polygon([point7, point8, point9, point10]);
      const polygon2 = new Polygon([
        point1,
        point2,
        point3,
        point4,
        point5,
        point6,
      ]);
      const polygon3 = new Polygon([
        point11,
        point12,
        point13,
        point14,
        point15,
        point16,
        point17,
        point18,
      ]);

      const pol1 = Canva.poly(
        polygon,
        Konva,
        Canva.polyDragFunc(canva, polygon, stateAccess.current)
      );

      const pol2 = Canva.poly(
        polygon2,
        Konva,
        Canva.polyDragFunc(canva, polygon2, stateAccess.current)
      );

      const pol3 = Canva.poly(
        polygon3,
        Konva,
        Canva.polyDragFunc(canva, polygon3, stateAccess.current)
      );

      var curr_pol = pol1;
      var curr_pol_obj = polygon;

      poly_type = 1;

      if (store.polygon === null) {
        const c1 = url.searchParams.get("fig_type");

        poly_type = parseInt(c1);

        if (poly_type === 2) {
          curr_pol = pol2;
          curr_pol_obj = polygon2;
        } else if (poly_type === 3) {
          curr_pol = pol3;
          curr_pol_obj = polygon3;
        }

        setStateP(curr_pol_obj);
        store.polygon = curr_pol_obj;
        store.poly_type = poly_type;
      } else {
        curr_pol_obj = store.polygon;
        poly_type = store.poly_type;
        curr_pol = Canva.poly(
          store.polygon,
          Konva,
          Canva.polyDragFunc(canva, store.polygon, stateAccess.current),
          1,
          store.polygon.x,
          store.polygon.y
        );

        setStateP(curr_pol_obj);
      }
    }

    if (stateCanva === null) {
      canva.init(
        [curr_pol],
        [
          stateAccess.current.addPoints(
            curr_pol_obj,
            Canva,
            Accessories.doOnDrag(
              curr_pol_obj,
              stateAccess.current,
              Polygon,
              canva,
              Point
            ),
            canva,
            curr_pol
          ),
          stateAccess.current.addAnglesButton(
            curr_pol_obj,
            Canva,
            canva,
            curr_pol,
            Point
          ),
          Accessories.addSizeLabels(curr_pol_obj, Canva, Konva),
        ],
        Canva,
        [Point, Polygon, Accessories, stateAccess.current]
      );

      curr_pol_obj.bindPoints();
      Accessories.justLabels(curr_pol_obj, canva, stateAccess.current);
      setStateArea(Polygon.getAreaByShoelaceFormula(curr_pol_obj.points));
      setHeatAreaLoad(1);

      heatAreaEvent(canva, setHeatAreaLoad, setStateHeatArea);

      canva.stage.on("mouseup touchend", () => {
        setStateArea(Polygon.getAreaByShoelaceFormula(curr_pol_obj.points));

        if (stateAccess.current.labels === true) {
          stateAccess.current.showLabels(canva);
        }
      });

      if (!stateAccess.current.labels) {
        stateAccess.current.hideLabels(canva);
      }

      stateAccess.current.hideAnglesButton(canva);

      if (store.spots.length > 0 && stateSpotDraw === 0) {
        store.spots = store.spots.filter((elm) => {
          let is = true;
          elm.points.forEach((itm) => {
            const intersects_arr = canva.stage.getAllIntersections({
              x: itm.x + elm.x,
              y: itm.y + elm.y,
            });

            const intersects = intersects_arr.filter((item) => {
              return item.getName() === "poly";
            });

            if (intersects.length > 0) {
            } else {
              is = false;
            }
          });

          return is;
        });
      }

      setStateC(canva);
    }

    if ((thermostat && stateCanva) || stateStep !== 1) {
      stateCanva.stage.find(".poly").forEach((elm) => {
        elm.draggable(false);
      });
      stateCanva.stage.find(".x_point").forEach((elm) => {
        if (elm.getAttr("parent_shape") === stateCanva.stage.find(".poly")[0]) {
          elm.draggable(false);
        }
      });
    } else if (stateCanva) {
      stateCanva.stage.find(".poly").forEach((elm) => {
        elm.draggable(true);
      });
      stateCanva.stage.find(".x_point").forEach((elm) => {
        elm.draggable(true);
      });
    }

    if (thermostat && stateCanva) {
      if (stateCanva) {
        stateCanva.stage.find(".x_point").forEach((elm) => {
          elm.draggable(false);
        });
        stateCanva.stage.find(".cold_spot").forEach((elm) => {
          elm.draggable(false);
        });
      }
    }

    if (thermostat && stateSpotDraw === 1) {
      if (stateCanva) {
        stateCanva.stage.find(".x_point").forEach((elm) => {
          elm.draggable(false);
        });
        stateCanva.stage.find(".cold_spot").forEach((elm) => {
          elm.draggable(false);
        });
      }
    }
  }, [
    stateCanva,
    statePoly,
    stateArea,
    stateStep,
    stateSpot,
    stateSpotDraw,
    i18n.language,
  ]);

  if (thermostat !== 1) {
    store.thermo = [];
  }

  return (
    <div style={{ display: "flex" }}>
      <SlideBar
        canva={stateCanva}
        poly={statePoly}
        access={stateAccess.current || { labels: true }}
        func={() => {
          setStateArea(Polygon.getAreaByShoelaceFormula(statePoly.points));
          setHeatAreaLoad(1);
        }}
      />
      <div id="container"></div>
      {PanelWrapped(
        stateStep,
        stateCanva,
        setStateHeatArea,
        statePoly,
        stateAccess.current,
        setHeatAreaLoad
      )}
      {Tip(
        stateStep,
        stateCanva,
        setStateHeatArea,
        setStateStep,
        setStateSpot,
        poly_type,
        stateArea,
        stateHeatArea,
        loading,
        setLoading,
        setError,
        heatAreaLoad,
        setHeatAreaLoad
      )}
      <div
        style={
          loading === 0 && ajax_error === 0
            ? { display: "none" }
            : {
                width: "100vw",
                height: "100vh",
                backgroundColor: "#1c1c34",
                position: "fixed",
                inset: "0 0 0 0",
                zIndex: "3",
              }
        }
      >
        <div className="loadingImgWrap">
          <img
            src="./img/loading.svg"
            className="loadingImg"
            alt="loading..."
          ></img>
          <div>
            <span>{t("Processing")}</span>
          </div>
        </div>
      </div>
      <div
        onClick={() => setError(0)}
        style={
          ajax_error === 0
            ? { display: "none" }
            : {
                width: "100vw",
                height: "100vh",
                backgroundColor: "#1c1c34",
                position: "fixed",
                inset: "0 0 0 0",
                zIndex: "3",
              }
        }
      >
        <div className="loadingImgWrap">
          <div>
            <span>{t("Calc error")}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModePolygon;
