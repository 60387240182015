import Panel from "../../Common/Panel";
import { addThermoFunc, addSpot } from "./helper";
import store from "../../Common/Store";
import { heatAreaEvent } from "../global_helper";
import { useEffect, useState } from "react";

const PanelWrapped = (
  stateStep,
  stateCanva,
  statePoly,
  stateAccess,
  setStateHeatArea,
  setHeatAreaLoad
) => {
  const [thermoAlert, setThermoAlert] = useState(0);
  const url_string = window.location.href;
  const url = new URL(url_string);
  const c = url.searchParams.get("thermo");
  const thermo = parseInt(c);

  if (!thermo) {
    return (
      <Panel
        page={stateStep === 1 ? 2 : 3}
        cold={stateStep === 2 ? true : false}
        spotFunc={(a, b, c) => {
          if (store.polygon != null) {
            store.spots.push(
              addSpot(
                a,
                b,
                c,
                store.polygon.points[0].x,
                store.polygon.points[0].y,
                stateCanva,
                stateAccess
              )
            );

            setHeatAreaLoad(1);

            heatAreaEvent(stateCanva, setHeatAreaLoad, setStateHeatArea);
          }
        }}
      />
    );
  } else {
    return (
      <Panel
        page="5"
        addThermo={(id, obj) => {
          if (thermoAlert === 0) {
            if (store.spots) {
              if (store.spots.length > 0) {
                alert(
                  "We recommend not placing thermostats on top of, behind, or near cold spot."
                );
              }
            }
          }
          setThermoAlert(1);
          store.thermo = [];
          stateCanva.stage.find(".thermo").forEach((elm) => {
            elm.destroy();
          });
          store.thermo.push(addThermoFunc(stateCanva, statePoly, id));
          store.thermoInfo = obj;
          return store.thermo;
        }}
      />
    );
  }
};

export default PanelWrapped;
