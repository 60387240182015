import TipBox from "../../Common/TipBox";
import { getHeatArea } from "./helper";
import { useNavigate } from "react-router-dom";
import Polygon from "../../Canva/Polygon";
import funcNext from "../funcNext";
import Canva from "../../Canva/Canva";
import Konva from "../../Canva/Konva";
import Point from "../../Canva/Point";
import getHeatAreaPadding_MonteCarlo from "../../Canva/getHeatAreaPadding_MonteCarlo";

const Tip = (
  polygon,
  stateStep,
  thermo,
  stateCanva,
  store,
  setStateHeatArea,
  setStateStep,
  setStateSpot,
  stateArea,
  stateHeatArea,
  loading,
  setLoading,
  setError,
  heatAreaLoad,
  setHeatAreaLoad
) => {
  const navigate = useNavigate();

  if (polygon) {
    if (!thermo) {
      return (
        <TipBox
          refreshArea={() => {
            getHeatAreaPadding_MonteCarlo(
              store,
              Polygon,
              Point,
              Konva,
              Canva,
              (val) => {
                setStateHeatArea(val);
                setHeatAreaLoad(0);
              }
            );
          }}
          heatAreaLoad={heatAreaLoad}
          spotDel={
            stateStep === 2
              ? (x, y) => {
                  const objcs = stateCanva.stage.getAllIntersections({
                    x: x,
                    y: y,
                  });
                  if (objcs) {
                    objcs.forEach((item) => {
                      if (item.getAttr("name") === "cold_spot") {
                        store.spots = store.spots.filter((itm) => {
                          if (itm.canva_obj === item) {
                            item.destroy();
                            stateCanva.stage
                              .find(".x_point")
                              .forEach((elm1) => {
                                if (elm1.getAttr("parent_shape") === item)
                                  elm1.getAttr("parent_group").destroy();
                              });
                            return false;
                          } else {
                            return true;
                          }
                        });

                        setStateHeatArea(getHeatArea(store, Polygon));
                      }
                    });
                  }
                }
              : null
          }
          tip={stateStep === 1 ? 2.2 : 3}
          funcNext={
            stateStep === 1
              ? () => {
                  setStateStep(2);
                }
              : false
          }
          funcPrev={
            stateStep === 2
              ? () => {
                  setStateSpot(0);
                  setStateStep(1);
                }
              : false
          }
          next={stateStep === 2 ? "/floortype?img=1" : ""}
          prev={stateStep === 1 ? "/mode" : ""}
          area={stateArea}
          heat_area={stateHeatArea}
        />
      );
    } else {
      return (
        <TipBox
          slider={false}
          tip="5"
          funcNext={() => {
            funcNext(
              loading,
              setLoading,
              stateCanva,
              store,
              setError,
              navigate,
              2
            );
          }}
          prev="/floortype?img=1"
          area={stateArea}
          heat_area={stateHeatArea}
        />
      );
    }
  } else {
    return (
      <TipBox
        refreshArea={() => {
          getHeatAreaPadding_MonteCarlo(
            store,
            Polygon,
            Point,
            Konva,
            Canva,
            (val) => {
              setStateHeatArea(val);
              setHeatAreaLoad(0);
            }
          );
        }}
        tip="2.2"
        prev={stateStep === 1 ? "/mode" : ""}
        funcNext={() => {}}
      />
    );
  }
};

export default Tip;
