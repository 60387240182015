import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
import item1 from "../images/item1.svg";
import item2 from "../images/item2.svg";
import item3 from "../images/item3.svg";
import item4 from "../images/item4.svg";
import item5 from "../images/item5.svg";

let thermo_objs = {};
function Panel(props) {
  const { t, i18n } = useTranslation();
  const [stateSpot, setStateSpot] = useState(1);
  const [stateSpotW, setStateSpotW] = useState(60);
  const [stateSpotH, setStateSpotH] = useState(60);
  const [currentPopupThermo, setCurrentPopupThermo] = useState(0);
  const [stateThermostats, setStateThermostats] = useState([]);
  const [thermoPopup, setThermoPopup] = useState(0);
  const [addedThermo, setAddedThermo] = useState([{ id: "none" }]);

  useEffect(() => {
    if (stateThermostats.length < 1 && Number(props.page) === 5) {
      fetch(process.env.REACT_APP_BE_HOST + "/thermostats")
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          thermo_objs = {};

          data.forEach((elm) => {
            thermo_objs[elm.id] = { ...elm };
          });

          setStateThermostats(data.reverse());
        });
    }
  }, []);

  return (
    <>
      <div
        className="sideBar"
        style={window.innerWidth < 550 ? { display: "none" } : {}}
      >
        <div style={{ padding: "10px" }}>
          <Link to="/">
            <img
              src="img/logo_main.png"
              style={{ width: "60%", margin: "20px" }}
              alt="logo"
            ></img>
          </Link>
        </div>
        <div className="sideBarNavWrap">
          <div className="sideNav">
            <div className="sideBarLi">
              <div
                className={
                  "side_nav_circle " +
                  (Number(props.page) === 1 ? "active" : "")
                }
              >
                <Link to="/mode">
                  {" "}
                  <svg
                    style={{ marginTop: "7px" }}
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="0.124512"
                      y="0.0244141"
                      width="16.7046"
                      height="16.7046"
                      fill="#8D8D8D"
                    />
                  </svg>
                </Link>
              </div>
              <Link to="/mode">
                {" "}
                <span className={Number(props.page) === 1 ? "active" : ""}>
                  {t("Room shape")}
                </span>
              </Link>
            </div>
            <div
              className={
                "side_nav_divider " + (Number(props.page) === 1 ? "active" : "")
              }
            ></div>

            <div className="sideBarLi">
              <div
                className={
                  "side_nav_circle " +
                  (Number(props.page) === 2 ? "active" : "")
                }
              >
                <ReactSVG src={`${item5}`} style={{ marginTop: "6px" }} />
              </div>
              <span className={Number(props.page) === 2 ? "active" : ""}>
                {t("Room size")}
              </span>
            </div>
            <div
              className={
                "side_nav_divider " + (Number(props.page) === 2 ? "active" : "")
              }
            ></div>
            <div className="sideBarLi">
              <div
                className={
                  "side_nav_circle " +
                  (Number(props.page) === 3 ? "active" : "")
                }
              >
                <ReactSVG src={`${item4}`} style={{ marginTop: "3px" }} />
              </div>
              <span className={Number(props.page) === 3 ? "active" : ""}>
                {t("Cold spots")}
              </span>
            </div>
            <div
              className={
                "side_nav_divider " + (Number(props.page) === 3 ? "active" : "")
              }
            ></div>

            <div
              className="spec_box"
              style={
                props.cold === true
                  ? {
                      marginLeft: "-8px",
                      width: "95%",
                      height: "214px",
                      borderRadius: "20px",
                      border: "1px solid #FC0A0A",
                      overflow: "hidden",
                    }
                  : { display: "none" }
              }
            >
              <div
                style={{
                  marginTop: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "self-start",
                }}
              >
                <span style={{ color: "#FFB800" }}>{t("Width")}</span>
                <input
                  style={{
                    color: "white",
                    border: "none",
                    borderBottom: "2px solid #FFB800",
                    backgroundColor: "transparent",
                    width: "60px",
                    outline: "none",
                    marginLeft: "10px",
                  }}
                  type="number"
                  value={stateSpotW}
                  step="1"
                  onChange={(e) => {
                    setStateSpotW(e.target.value);
                  }}
                />
                <span style={{ color: "#FFB800" }}>cm</span>
              </div>

              <div
                style={{
                  marginTop: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "self-start",
                }}
              >
                <span style={{ color: "#FFB800" }}>{t("Height")}</span>
                <input
                  style={{
                    color: "white",
                    border: "none",
                    borderBottom: "2px solid #FFB800",
                    backgroundColor: "transparent",
                    width: "60px",
                    outline: "none",
                    marginLeft: "10px",
                  }}
                  type="number"
                  value={stateSpotH}
                  step="1"
                  onChange={(e) => {
                    setStateSpotH(e.target.value);
                  }}
                />
                <span style={{ color: "#FFB800" }}>cm</span>
              </div>
              <div className="cold_spot_wrap">
                <svg
                  onClick={() => {
                    setStateSpot(1);
                  }}
                  className={stateSpot === 1 ? "active" : ""}
                  width="27"
                  height="27"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.124512"
                    y="0.0244141"
                    width="16.7046"
                    height="16.7046"
                    fill="#626B8B"
                  />
                </svg>

                <svg
                  onClick={() => {
                    setStateSpot(2);
                  }}
                  className={stateSpot === 2 ? "active" : ""}
                  width="27"
                  height="27"
                  viewBox="0 0 27 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.624023 0.813477L26.3813 26.5708H0.624023V0.813477Z"
                    fill="#626B8B"
                  />
                </svg>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "10px",
                }}
              >
                <button
                  style={{
                    borderRadius: "30px",
                    border: "none",
                    padding: "8px 20px",
                    cursor: "pointer",
                    background:
                      "linear-gradient(137.42deg, #FFB800 0%, #FD5A06 104.6%)",
                  }}
                  onClick={() => {
                    props.spotFunc(stateSpot, stateSpotW, stateSpotH);
                  }}
                >
                  {t("Spot")}
                </button>
              </div>
              <p
                style={{
                  color: "red",
                  fontSize: "10px",
                  padding: "2px 8px",
                  textAlign: "center",
                }}
              >
                *Cold spots that are outside the room <br /> will be removed!
              </p>
            </div>

            <div
              style={props.cold === true ? {} : { display: "none" }}
              className={
                "side_nav_divider " + (Number(props.page) === 3 ? "active" : "")
              }
            ></div>

            <div className="sideBarLi">
              <div
                className={
                  "side_nav_circle " +
                  (Number(props.page) === 4 ? "active" : "")
                }
              >
                <ReactSVG src={`${item3}`} style={{ marginTop: "5px" }} />
              </div>
              <span className={Number(props.page) === 4 ? "active" : ""}>
                {t("Floor type")}
              </span>
            </div>
            <div
              className={
                "side_nav_divider " + (Number(props.page) === 4 ? "active" : "")
              }
            ></div>
            <div className="sideBarLi">
              <div
                className={
                  "side_nav_circle " +
                  (Number(props.page) === 5 ? "active" : "")
                }
              >
                <ReactSVG src={`${item1}`} style={{ marginTop: "4px" }} />
              </div>
              <span className={Number(props.page) === 5 ? "active" : ""}>
                {t("Thermostat")}
              </span>
            </div>
            <div
              style={Number(props.page) === 5 ? {} : { display: "none" }}
              className={
                "side_nav_divider " + (Number(props.page) === 5 ? "active" : "")
              }
            ></div>

            <div
              className="spec_box"
              style={
                props.addThermo
                  ? {
                      marginLeft: "-8px",
                      width: "95%",
                      height: "290px",
                      borderRadius: "20px",
                      border: "1px solid #FC0A0A",
                      overflow: "auto",
                    }
                  : { display: "none" }
              }
            >
              {stateThermostats.map((itm, i) => {
                return (
                  <div
                    onMouseEnter={() => {
                      setCurrentPopupThermo(itm.id);
                    }}
                    onMouseLeave={() => {
                      setCurrentPopupThermo(null);
                    }}
                    key={itm.id}
                    style={{
                      fontSize: "12px",
                      margin: "10px 12px 4px 0px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>
                      <span
                        className={
                          currentPopupThermo === itm.id ||
                          addedThermo === itm.id
                            ? "list_bullet active"
                            : "list_bullet"
                        }
                      >
                        {i + 1}
                      </span>
                      <span
                        onMouseEnter={() => {
                          setThermoPopup(1);
                        }}
                        onMouseLeave={() => {
                          setThermoPopup(0);
                        }}
                        style={{
                          color: "white",
                          cursor: "pointer",
                          display: "inline-block",
                          maxWidth: "100px",
                          height: "15px",
                          overflow: "hidden",
                          verticalAlign: "middle",
                        }}
                      >
                        {itm.name}
                      </span>
                    </span>
                    <button
                      className="add_button_plus"
                      style={
                        currentPopupThermo === itm.id && addedThermo !== itm.id
                          ? {}
                          : { display: "none" }
                      }
                      onClick={() => {
                        setAddedThermo(
                          props.addThermo(itm.id, itm)[0].getAttr("id")
                        );
                      }}
                    >
                      +
                    </button>
                  </div>
                );
              })}
            </div>

            <div
              className={
                "side_nav_divider " +
                (Number(props.page) === 5 || Number(props.page) === 6
                  ? "active"
                  : "")
              }
            ></div>

            <div className="sideBarLi">
              <div
                className={
                  "side_nav_circle " +
                  (Number(props.page) === 6 ? "active" : "")
                }
              >
                <ReactSVG src={`${item2}`} style={{ marginTop: "4px" }} />
              </div>
              <span className={Number(props.page) === 6 ? "active" : ""}>
                {t("Result")}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div
        style={
          thermoPopup === 1
            ? {
                border: "0.5px solid gray",
                borderRadius: "10px",
                backgroundColor: "#373749",
                position: "absolute",
                inset: "0 0 0 0",
                margin: "auto",
                width: "400px",
                height: "400px",
                zIndex: "100",
                marginLeft: "260px",
              }
            : { display: "none" }
        }
      >
        {currentPopupThermo && Object.keys(thermo_objs).length > 0 && (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "35px",
              }}
            >
              <img
                style={{
                  width: "160px",
                  borderRadius: "10px",
                  maxHeight: "300px",
                }}
                alt="img"
                src={
                  thermo_objs[currentPopupThermo].name &&
                  thermo_objs[currentPopupThermo].img !== "test" &&
                  thermo_objs[currentPopupThermo].img !== ""
                    ? thermo_objs[currentPopupThermo].img
                    : "img/thermo1.png"
                }
              ></img>
            </div>
            <div
              style={{
                color: "white",
                textAlign: "left",
                padding: "4px 20px 4px 20px",
              }}
            >
              <p style={{ fontWeight: "bold" }}>
                {thermo_objs[currentPopupThermo].name}
              </p>
              <p
                style={{
                  fontSize: "12px",
                  maxHeight: "200px",
                  overflow: "hidden",
                }}
              >
                {thermo_objs[currentPopupThermo].description}
              </p>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default Panel;
