import { Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import ModePage from "./Pages/ModePage";
import ModeImg from "./Pages/DrawCanvaPage/ModeImg";
import ModePolygon from "./Pages/PresetCanvaPage/ModePolygon";
import SelectPolygon from "./Pages/SelectPolygon";
import FloorType from "./Pages/FloorType/FloorType";
import Result from "./Pages/Result";
import PDFGen from "./Pages/PDFGen";
import { useTranslation } from "react-i18next";

function App() {
  const { t } = useTranslation();
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="mode" element={<ModePage />} />
        <Route path="imgmode" element={<ModeImg />} />
        <Route path="standartmode" element={<ModePolygon />} />
        <Route path="selectshape" element={<SelectPolygon />} />
        <Route path="floortype" element={<FloorType />} />
        <Route path="result" element={<Result />} />
        <Route path="pdf-page" element={<PDFGen />} />
        <Route
          path="*"
          element={
            <main style={{ padding: "1rem", color: "white" }}>
              <h1>{t("404")}</h1>
            </main>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
