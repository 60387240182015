import TipBox from "../../Common/TipBox";
import Canva from "../../Canva/Canva";
import Konva from "../../Canva/Konva";
import Polygon from "../../Canva/Polygon";
import store from "../../Common/Store";
import { useNavigate } from "react-router-dom";
import Point from "../../Canva/Point";
import getHeatAreaPadding_MonteCarlo from "../../Canva/getHeatAreaPadding_MonteCarlo";
import funcNext from "../funcNext";

const Tip = (
  stateStep,
  stateCanva,
  setStateHeatArea,
  setStateStep,
  setStateSpot,
  poly_type,
  stateArea,
  stateHeatArea,
  loading,
  setLoading,
  setError,
  heatAreaLoad,
  setHeatAreaLoad
) => {
  const navigate = useNavigate();
  const url_string = window.location.href;
  const url = new URL(url_string);
  const c = url.searchParams.get("thermo");
  const thermo = parseInt(c);

  if (!thermo) {
    return (
      <TipBox
        refreshArea={() => {
          getHeatAreaPadding_MonteCarlo(
            store,
            Polygon,
            Point,
            Konva,
            Canva,
            (val) => {
              setStateHeatArea(val);
              setHeatAreaLoad(0);
            }
          );
        }}
        heatAreaLoad={heatAreaLoad}
        spotDel={
          stateStep === 2
            ? (x, y) => {
                const objcs = stateCanva.stage.getAllIntersections({
                  x: x,
                  y: y,
                });
                if (objcs) {
                  objcs.forEach((item) => {
                    if (item.getAttr("name") === "cold_spot") {
                      store.spots = store.spots.filter((itm) => {
                        if (itm.canva_obj === item) {
                          item.destroy();
                          stateCanva.stage.find(".x_point").forEach((elm1) => {
                            if (elm1.getAttr("parent_shape") === item)
                              elm1.getAttr("parent_group").destroy();
                          });
                          return false;
                        } else {
                          return true;
                        }
                      });
                      setHeatAreaLoad(1);
                      getHeatAreaPadding_MonteCarlo(
                        store,
                        Polygon,
                        Point,
                        Konva,
                        Canva,
                        (val) => {
                          setStateHeatArea(val);
                          setHeatAreaLoad(0);
                        }
                      );
                    }
                  });
                }
              }
            : null
        }
        tip={stateStep === 1 ? 2 : 3}
        funcNext={
          stateStep === 1
            ? () => {
                setStateStep(2);
              }
            : false
        }
        funcPrev={
          stateStep === 2
            ? () => {
                setStateSpot(0);
                setStateStep(1);
              }
            : false
        }
        next={stateStep === 2 ? "/floortype?fig_type=" + poly_type : ""}
        prev={stateStep === 1 ? "/selectshape" : ""}
        area={stateArea}
        heat_area={stateHeatArea}
      />
    );
  } else {
    return (
      <TipBox
        refreshArea={() => {
          getHeatAreaPadding_MonteCarlo(
            store,
            Polygon,
            Point,
            Konva,
            Canva,
            (val) => {
              setStateHeatArea(val);
              setHeatAreaLoad(0);
            }
          );
        }}
        heatAreaLoad={heatAreaLoad}
        slider={false}
        tip="5"
        funcNext={() => {
          funcNext(
            loading,
            setLoading,
            stateCanva,
            store,
            setError,
            navigate,
            1
          );
        }}
        prev={"/floortype?fig_type=" + poly_type}
        area={stateArea}
        heat_area={stateHeatArea}
      />
    );
  }
};

export default Tip;
