import TipBox from "../Common/TipBox";
import store from "../Common/Store";
import Panel from "../Common/Panel";
import { useEffect, useState } from "react";
import LangSelect from "../Common/LangSelect";
import { useTranslation, Trans } from "react-i18next";

function ModePage() {
  const [state, setState] = useState(1);
  const { t, i18n } = useTranslation(localStorage.getItem("lang"));
  useEffect(() => {
    store.empty();
    i18n.changeLanguage(localStorage.getItem("lang"));
  }, [i18n.language]);

  return (
    <div>
      <div>
        <h1
          style={{
            color: "#5F5F5F",
            fontSize: "24px",
            fontWeight: 400,
            textAlign: "center",
            top: "-500px",
            bottom: 0,
            left: 0,
            right: 0,
            position: "absolute",
            margin: "auto",
            height: "40px",
            paddingLeft: "140px",
          }}
        >
          <Trans i18nKey="Choose plan"></Trans>
        </h1>
        <div
          style={{
            width: "70vw",
            minWidth: "320px",
            maxWidth: "660px",
            height: "400px",
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            top: 0,
            margin: "auto",
            paddingLeft: "140px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
            }}
          >
            <div
              style={{
                margin: "10px",
                width: "300px",
                height: "240px",
                background:
                  "linear-gradient(138.31deg, rgba(255, 255, 255, 0.1) -0.24%, rgba(30, 36, 62, 0.1) 123.16%)",
                backdropFilter: "blur(50px)",
                borderRadius: "14px",
                border: "1px solid",
                borderImageSource:
                  "linear-gradient(119.15deg, rgba(255, 255, 255, 0.37) -0.5%, rgba(255, 255, 255, 0) 104.16%)",
                display: "inline-block",
              }}
            >
              <div>
                <img
                  alt=""
                  src="img/shapes.png"
                  width="80%"
                  style={{ marginTop: "50px" }}
                  onClick={(e) => {
                    setState(1);
                  }}
                ></img>

                <p
                  style={{
                    color: "white",
                    fontSize: "14px",
                    margin: "2px",
                    marginTop: "25px",
                  }}
                >
                  <Trans i18nKey="Choose room shape"></Trans>
                </p>
                <div className="round" style={{ left: "130px" }}>
                  <input
                    type="radio"
                    id="radio1"
                    checked={state === 1 ? "checked" : ""}
                    name="mode"
                    onChange={(e) => {
                      if (e.target.checked === true) {
                        setState(1);
                      }
                    }}
                  />
                  <label htmlFor="radio1"></label>
                </div>
              </div>
            </div>
            <div
              style={{
                margin: "10px",
                width: "300px",
                height: "240px",
                background:
                  "linear-gradient(138.31deg, rgba(255, 255, 255, 0.1) -0.24%, rgba(30, 36, 62, 0.1) 123.16%)",
                backdropFilter: "blur(50px)",
                borderRadius: "14px",
                border: "1px solid",
                borderImageSource:
                  "linear-gradient(119.15deg, rgba(255, 255, 255, 0.37) -0.5%, rgba(255, 255, 255, 0) 104.16%)",
                display: "inline-block",
              }}
            >
              <img
                alt=""
                src="img/room_img.png"
                width="40%"
                style={{ marginTop: "20px" }}
                onClick={(e) => {
                  setState(2);
                }}
              ></img>

              <p
                style={{
                  color: "white",
                  fontSize: "14px",
                  margin: "2px",
                  marginTop: "10px",
                }}
              >
                <Trans i18nKey="Create custom shape"></Trans>
              </p>
              <div className="round" style={{ left: "130px" }}>
                <input
                  type="radio"
                  id="radio2"
                  checked={state === 2 ? "checked" : ""}
                  name="mode"
                  onChange={(e) => {
                    if (e.target.checked === true) {
                      setState(2);
                    }
                  }}
                />
                <label htmlFor="radio2"></label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <TipBox
        tip="1"
        next={state === 1 ? "/selectshape" : "/imgmode"}
        prev={false}
      />
      <Panel page="1" />
      <LangSelect func={i18n.changeLanguage} />
    </div>
  );
}

export default ModePage;
