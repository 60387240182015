/** @jsxImportSource @emotion/react */
import { useForm } from "react-hook-form";
import "react-medium-image-zoom/dist/styles.css";
import { pdf } from "@react-pdf/renderer";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Zoom from "react-medium-image-zoom";
import { Link, useNavigate } from "react-router-dom";
import { css } from "@emotion/react";
import Canva from "../Canva/Canva";
import Konva from "../Canva/Konva";
import Point from "../Canva/Point";
import Polygon from "../Canva/Polygon";
import { getHeatAreaPadding_MonteCarlo_main } from "../Canva/getHeatAreaPadding_MonteCarlo";
import store from "../Common/Store";
import Panel from "../Common/Panel";
import TipBox from "../Common/TipBox";

import { ReactSVG } from "react-svg";
import cross from "../images/cross.svg";
import { PDFDownload, MyDocument } from "./PDFGen";
import DetailsPopup from "./DetailsPopup";

function Result() {
  const navigate = useNavigate();
  const [page, setPageState] = useState("/");
  const [detailsPopup, setDetailsPopup] = useState(0);
  const [emailPopup, setEmailPopup] = useState(0);
  const [polyData, setPolyData] = useState(null);
  const [sending, setSending] = useState(0);
  const [error, setError] = useState(0);
  const { t, i18n } = useTranslation();
  const {
    register,
    trigger,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const handleGeneratePDF = async (doc) => {
    const blob = await pdf(doc).toBlob();
    return blob;
  };

  useEffect(() => {
    const url_string = window.location.href;
    const url = new URL(url_string);
    const c = Number(url.searchParams.get("mode"));
    if (c === 1) {
      setPageState("/standartmode");
    } else {
      setPageState("/imgmode");
    }
  }, []);

  useEffect(() => {
    const room_area = store.polygon
      ? Polygon.getAreaByShoelaceFormula(store.polygon.points)
      : 0;
    const spots = store.spots.map((itm) => {
      let area = Polygon.getAreaByShoelaceFormula(itm.points);

      return {
        spot_point: itm.getPointsCoords(),
        spot_area: area,
        spot_shift: { x: itm.x, y: itm.y },
        type: itm.type,
      };
    });

    const thermo = store.thermo.map((itm) => {
      return {
        coord: { x: itm.x(), y: itm.y(), id: itm.attrs.id },
      };
    });
    let data = {};

    if (store.polygon) {
      data = {
        room_points: store.polygon.getPointsCoords(),
        room_side_length: Canva.getSidesLength([
          ...store.polygon.points,
          store.polygon.points[0],
        ]),
        room_side_center: Canva.getSidesCenters([
          ...store.polygon.points,
          store.polygon.points[0],
        ]),
        room_shift: { x: store.polygon.x, y: store.polygon.y },
        room_full_area: room_area,
        floor1: store.floor1,
        spots: spots,
        thermostats: thermo,
        pixels_per_meter: Canva.scale,
      };

      store.spots.forEach((spot) => {
        spot.unbindPoints();
      });
      store.polygon.unbindPoints();

      store.spotsPrep = store.spots.map((itm) => {
        let area = Polygon.getAreaByShoelaceFormula(itm.points);

        return {
          spot_point: itm.getPointsCoords(),
          spot_area: area,
          spot_shift: { x: itm.x, y: itm.y },
          type: itm.type,
        };
      });

      store.thermoPrep = store.thermo.map((itm) => {
        return {
          coord: { x: itm.x(), y: itm.y(), id: itm.attrs.id },
        };
      });

      store.room_heated_area_padding = getHeatAreaPadding_MonteCarlo_main(
        store,
        Polygon,
        Point,
        Konva,
        Canva
      );
      store.room_area = store.polygon
        ? Polygon.getAreaByShoelaceFormula(store.polygon.points)
        : 0;

      store.polygon.getPointsCoordsPrep = store.polygon.getPointsCoords();

      if (store.polygon.points) {
        store.polygon.points.forEach((point) => {
          delete point.line;
        });
      }

      localStorage.setItem("store", JSON.stringify(store));
    } else {
      navigate("/");
    }

    setPolyData(data);
  }, []);

  function onSubmit(sendData) {
    if (polyData !== null && sending === 0) {
      setSending(1);
      handleGeneratePDF(<MyDocument store={store} />).then((pdfData) => {
        const formData = new FormData();
        formData.append(
          "pdf",
          pdfData,
          "HEATMAT_installation_recommendations.pdf"
        );
        formData.append("name", sendData.name);
        formData.append("email", sendData.email);

        fetch(process.env.REACT_APP_BE_HOST + "/user/checkin", {
          method: "POST",
          headers: {
            Accept: "application/json",
          },
          body: formData,
        })
          .then((res) => {
            setSending(0);
            if (res.ok) {
              setError(1);
              setTimeout(() => {
                setError(0);
              }, 2000);
              return res.json();
            } else {
              setError(2);
              setTimeout(() => {
                setError(0);
              }, 2000);
              return null;
            }
          })
          .then((data) => {
            return data;
          })
          .catch((error) => {
            setError(2);
            setTimeout(() => {
              setError(0);
            }, 2000);
            return error;
          });
      });
    } else {
      alert("Wait... Data is still loading...");
    }
  }

  return (
    <div>
      <div
        style={{
          width: "80vw",
          minWidth: "320px",
          maxWidth: "930px",
          height: "400px",
          margin: "auto",
          paddingLeft: "140px",
        }}
      >
        <h1
          style={{
            color: "#979797",
            textAlign: "right",
            fontSize: "14px",
            padding: "10px 40px",
          }}
        >
          {t("Result calculation")}
        </h1>
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          <div style={{ width: "60%", overflow: "hidden" }}>
            {store.final_img && (
              <Zoom>
                <img
                  alt="img"
                  style={{
                    maxWidth: "90%",
                    maxHeight: "400px",
                    marginTop: "8px",
                  }}
                  src={store.final_img}
                ></img>
              </Zoom>
            )}
          </div>
          <div
            style={{
              width: "40%",
              textAlign: "left",
              alignItems: "center",
              display: "flex",
            }}
          >
            <div>
              <span style={{ color: "#FC0A0A", fontSize: "22px" }}>
                {t("Calculation complete")}
              </span>
              {store.polygon && polyData !== null ? (
                <>
                  <div style={{ color: "white" }}>
                    <span style={{ color: "lightgray", fontWeight: "bold" }}>
                      {t("Room area")}
                    </span>{" "}
                    {parseFloat(polyData.room_full_area).toFixed(2)} m
                    <sup>2</sup>
                  </div>

                  <div style={{ color: "white" }}>
                    <span style={{ color: "lightgray", fontWeight: "bold" }}>
                      {t("Floor heating area")}
                    </span>{" "}
                    {store.mat_data
                      ? store.mat_data.props
                        ? store.mat_data.props.TotalMatCoverage
                        : "---"
                      : "---"}{" "}
                    m<sup>2</sup>
                  </div>
                </>
              ) : (
                <div style={{ padding: "10px", textAlign: "center" }}>
                  <img
                    src="./img/loading.svg"
                    className="loadingImg"
                    alt="loading..."
                  ></img>
                </div>
              )}
            </div>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          <div style={{ width: "60%" }}></div>
          {store.final_img && (
            <div style={{ width: "40%", marginTop: "20px" }}>
              <button
                style={{
                  cursor: "pointer",
                  margin: "0 2px",
                  padding: "10px 25px",
                  fontWeight: "bold",
                  color: "black",
                  borderRadius: "20px",
                  background:
                    "linear-gradient(137.42deg, #FFB800 0%, #FD5A06 104.6%)",
                }}
                onClick={() => {
                  setDetailsPopup(1);
                }}
                css={css`
                  &:hover {
                    border-color: white;
                  }
                `}
              >
                {t("Details")}
              </button>
              <Link to="/pdf-page" target="_blank">
                <button
                  style={{
                    cursor: "pointer",
                    margin: "0 2px",
                    padding: "10px 25px",
                    fontWeight: "bold",
                    color: "black",
                    borderRadius: "20px",
                    background:
                      "linear-gradient(131.4deg, #7BFB6D 10.81%, #0F9300 75.02%)",
                  }}
                  css={css`
                    &:hover {
                      border-color: white;
                    }
                  `}
                >
                  {t("Save PDF")}
                </button>
              </Link>
              <button
                onClick={() => {
                  setEmailPopup(1);
                }}
                style={{
                  cursor: "pointer",
                  margin: "0 2px",
                  padding: "10px 25px",
                  fontWeight: "bold",
                  color: "black",
                  borderRadius: "20px",
                  background:
                    "linear-gradient(121.93deg, #55B4E9 -4.53%, #2059ED 77.31%)",
                }}
                css={css`
                  &:hover {
                    border-color: white;
                  }
                `}
              >
                {t("Send by email 2")}
              </button>
            </div>
          )}
        </div>
      </div>

      {detailsPopup === 1 ? (
        <DetailsPopup setDetailsPopup={setDetailsPopup} store={store} />
      ) : null}

      <div
        style={
          emailPopup === 1
            ? {
                backdropFilter: "blur(10px)",
                backgroundColor: "rgba(28, 31, 55, 0.6)",
                position: "fixed",
                inset: "0 0 0 0",
                margin: "auto",
                width: "100vw",
                height: "100vh",
                zIndex: "100",
              }
            : { display: "none" }
        }
      >
        <div
          style={{
            border: "0.5px solid gray",
            borderRadius: "10px",
            backgroundColor: "#373749",
            position: "absolute",
            inset: "0 0 0 0",
            margin: "auto",
            width: "400px",
            height: "400px",
            zIndex: "100",
          }}
        >
          <ReactSVG
            src={`${cross}`}
            className="crossBtn"
            onClick={() => {
              setEmailPopup(0);
            }}
          />
          <h4
            style={{
              color: "white",
              textAlign: "center",
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "60px",
              marginBottom: "30px",
              width: "90%",
            }}
          >
            {t("To save or send")}
          </h4>

          <div className="login">
            <div className="form">
              <form id="user-create-form" onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <input
                    style={{
                      color: "white",
                      padding: "10px",
                      margin: "10px",
                      backgroundColor: "transparent",
                      border: "2px solid #fc0a0a",
                      borderRadius: "30px",
                      height: "20px",
                      width: "80%",
                    }}
                    name="name"
                    type="text"
                    placeholder={t("Name")}
                    required
                    {...register("name", { required: true, minLength: 2 })}
                  />
                </div>
                <div>
                  <input
                    style={{
                      color: "white",
                      padding: "10px",
                      margin: "10px",
                      backgroundColor: "transparent",
                      border: "2px solid #fc0a0a",
                      borderRadius: "30px",
                      height: "20px",
                      width: "80%",
                    }}
                    name="email"
                    type="text"
                    placeholder={t("Email")}
                    required
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                    {...register("email", {
                      minLength: 4,
                      required: true,
                      pattern: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i,
                    })}
                  />
                </div>
                <div style={{ color: "lightgreen", fontWeight: "bold" }}>
                  {error === 1 && <span>Sent successfully</span>}
                </div>
                <div style={{ color: "red", fontWeight: "bold" }}>
                  {error === 2 && <span>An error has occurred</span>}
                </div>
                <div style={{ color: "white" }}>
                  {sending === 1 && <span>Sending...</span>}
                </div>
                <div style={{ color: "rgb(252, 10, 10)" }}>
                  {errors.email && <span>Incorrect Email</span>}
                </div>
                <div style={{ color: "rgb(252, 10, 10)" }}>
                  {errors.name && <span>Incorrect name</span>}
                </div>
                <div style={{ marginTop: "50px" }}>
                  <PDFDownload
                    PDF={<MyDocument store={store} />}
                    onClick={(e) => {
                      if (
                        watch("name").length > 0 &&
                        watch("email").length > 0 &&
                        !errors.email &&
                        !errors.name
                      ) {
                        handleSubmit(onSubmit)();
                      } else {
                        e.preventDefault();
                        trigger();
                      }
                    }}
                    btn={
                      <button
                        type="button"
                        style={{
                          cursor: "pointer",
                          margin: "0 2px",
                          padding: "10px 25px",
                          fontWeight: "bold",
                          color: "black",
                          borderRadius: "20px",
                          background:
                            "linear-gradient(131.4deg, #7BFB6D 10.81%, #0F9300 75.02%)",
                        }}
                        css={css`
                          &:hover {
                            border-color: white;
                          }
                        `}
                      >
                        {t("Save PDF")}
                      </button>
                    }
                  />
                  <button
                    type="submit"
                    style={{
                      marginLeft: "10px",
                      cursor: "pointer",
                      padding: "10px 20px",
                      fontWeight: "bold",
                      color: "black",
                      borderRadius: "20px",
                      background:
                        "linear-gradient(121.93deg, #55B4E9 -4.53%, #2059ED 77.31%)",
                    }}
                  >
                    {t("Send by email")}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <Panel page="6" />
      <TipBox
        tip="6"
        funcPrev={() => {
          store.thermo = [];
          navigate(page + "?thermo=1");
        }}
      />
    </div>
  );
}

export default Result;
