import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';


function Result(props) {
return(

    <div className="selectWrap">

        <Select 
              MenuProps={{
                style: {
      
                },
                id:"lang-1"
               }}
         className='langSelect'
         style={{
         fontWeight:"bold", 
         fontSize:"20px", 
         position:"fixed", 
         width: "80px", 
         height:"40px", 
         inset:"0px 0px 100% 100%", 
         marginLeft:"-120px", 
         marginTop:"30px", 
         color:"orange", 
         border: "none", 
         background: "transparent"}}
          labelId="lang-select"
          id="lang-select"
          value={localStorage.getItem('lang') || "en"}
          onChange={(e)=>{localStorage.setItem('lang', e.target.value); props.func(e.target.value)}}
        >
          <MenuItem root={{background: "red"}} value="en">EN</MenuItem>
          <MenuItem value="de">DE</MenuItem>
          <MenuItem value="ua">UA</MenuItem>
          <MenuItem value="ru">RU</MenuItem>
          <MenuItem value="pl">PL</MenuItem>
          <MenuItem value="es">ES</MenuItem>
          <MenuItem value="it">IT</MenuItem>
        </Select>
 
    </div>

    );
}

export default Result;
