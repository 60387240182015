import cross from "../images/cross.svg";
import { ReactSVG } from "react-svg";
import { useTranslation } from "react-i18next";

function DetailsPopup({ setDetailsPopup, store }) {
  const { t, i18n } = useTranslation();

  const mats = {};

  Object.values(store.mat_data.matParams).forEach((elm) => {
    if (mats[elm.MatId] === undefined) {
      mats[elm.MatId] = [elm];
    } else {
      mats[elm.MatId].push(elm);
    }
  });

  return (
    <div
      style={{
        backdropFilter: "blur(10px)",
        backgroundColor: "rgba(28, 31, 55, 0.6)",
        position: "fixed",
        inset: "0 0 0 0",
        margin: "auto",
        width: "100vw",
        height: "100vh",
        zIndex: "100",
      }}
    >
      <div
        style={{
          border: "0.5px solid gray",
          borderRadius: "10px",
          backgroundColor: "#373749",
          position: "absolute",
          inset: "0 0 0 0",
          margin: "auto",
          width: "650px",
          height: "400px",
          zIndex: "100",
        }}
      >
        <ReactSVG
          src={`${cross}`}
          className="crossBtn"
          style={{ margin: "-53px 0 23px 655px" }}
          onClick={() => {
            setDetailsPopup(0);
          }}
        />

        <h4 style={{ color: "white", textAlign: "center", marginTop: "40px" }}>
          {t("List of parts")}
        </h4>
        <p style={{ fontSize: "14px", color: "lightgray", padding: "10px" }}>
          {t("Learn more")}
        </p>
        <hr style={{ width: "90%" }} />
        <ul
          style={{
            textAlign: "left",
            color: "white",
            padding: "0 30px",
            overflow: "auto",
          }}
          className="specUl"
        >
          {Object.keys(mats).map((item, i) => {
            return (
              <li key={i} style={{ padding: "6px 0" }}>
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      backgroundColor: "rgb(252, 10, 10)",
                      width: "10px",
                      height: "10px",
                      borderRadius: "100%",
                      margin: "6px 6px 6px 0",
                    }}
                  >
                    {" "}
                  </div>{" "}
                  <div>
                    {" "}
                    {store.mat_data ? mats[item].length : ""}
                    {" x "}
                    {store.mat_data ? item : ""}{" "}
                  </div>{" "}
                  <div
                    style={{ borderBottom: "2px dashed white", flex: "auto" }}
                  ></div>
                  <div>{mats[item][0].Size}м²</div>{" "}
                  <div
                    style={{
                      borderBottom: "2px dashed white",
                      flex: "auto",
                      maxWidth: "20px",
                    }}
                  ></div>
                  <div>
                    {mats[item][0].Wattage}
                    W/sqm
                  </div>
                  <div
                    style={{
                      borderBottom: "2px dashed white",
                      flex: "auto",
                      maxWidth: "20px",
                    }}
                  ></div>
                  <div>
                    {store.mat_data
                      ? store.mat_data.chosenMatDescription.type
                      : ""}
                  </div>
                </div>
              </li>
            );
          })}

          <li style={{ padding: "6px 0" }}>
            <div style={{ display: "flex" }}>
              <div
                style={{
                  backgroundColor: "rgb(252, 10, 10)",
                  width: "10px",
                  height: "10px",
                  borderRadius: "100%",
                  margin: "6px 6px 6px 0",
                }}
              >
                {" "}
              </div>{" "}
              <div>
                1 x {store.thermoInfo ? store.thermoInfo.name : "thermostat"}
              </div>
              <div
                style={{ borderBottom: "2px dashed white", flex: "auto" }}
              ></div>
              <div>
                {store.thermoInfo
                  ? store.thermoInfo.description
                    ? store.thermoInfo.description.substring(0, 40)
                    : "thermostat"
                  : "thermostat"}
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default DetailsPopup;
